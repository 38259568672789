import http from '@/_helper/api-services'
import "./produtos.scss";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";
import listagemProdutos from "@/components/ListagemProdutos/listagemProdutos.vue";
// import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
// import listagemProdutosFisicos from "@/components/ListagemProdutosFisicos/ListagemProdutosFisicos.vue";

export default {
    name: "produtos",
    components: {
        LinkPatrocinador,
        listagemProdutos
        // SideBar,
        // PremiosDashboard,
        // listagemProdutosFisicos
    },
    data() {
        return {
            // dadosUsuario: {},
            nomeLogin: '',
            produtos: [],
        };
    },
    beforeMount(){
        // this.obterDadosUsuario();
    },
    mounted() {
        this.obterNomeLogado();
        this.obterProdutos();
        //this.$bvModal.show('modal-info');
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        // obterDadosUsuario() {
        //     this.$loading(true);
        //     http.get('/Dashboard/getBarStatus/').then((responde) => {
        //         this.dadosUsuario = responde.data;
        //         this.$loading(false);
        //     },
        //     error => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         // else
        //         //     this.$snotify.error("An unexpected error has occurred");
        //     });
        //     this.$loading(false);
        // },

        obterProdutos() {
            this.$loading(true);
            http.get('/product/searchActive').then((response) => {
                this.produtos = response.data;
                this.produtos = this.produtos.filter(produto => produto.idProduto === 3);
                this.$loading(false);

                
            }).catch((error) => {
                this.$snotify.error("Ocorreu um erro ao buscar pelo produto");
                this.$loading(false);
            });
        }
        
    }
}