<template>
    <div id="app" ref="appElement">
        <vue-snotify timeout="4000" showProgressBar="true"></vue-snotify>

        <!-- <div class="notification-bar">
            <div class="notification-tit">
                <i><img src="@/assets/imgs/alert-octagon-outline.svg" /></i>
                <span>{{$t('generico.notification-tit')}}</span>
            </div>
            <div class="notification-txt">{{$t('generico.notification-txt')}} </div>
        </div> -->
        <main>
            <section class="side-bar" v-if="exibir">
                <SideBar></SideBar>
            </section>

            <div class="box-default"> 
                <div class="header-fixed" v-if="exibir">
                    <HeaderBar v-bind:searchMenu="idUsuario != null" v-bind:idUsuario="idUsuario"></HeaderBar>
                </div>

                <section class="side-bar side-bar-mob" v-if="exibir">
                    <SideBar></SideBar>
                </section>

                <div class="general-content">
                    <!-- <div class="side-bar-menu" v-if="exibir">
                        <MenuBar v-if="exibir" v-bind:searchMenu="idUsuario != null" v-bind:idUsuario="idUsuario"></MenuBar>    
                    </div> -->
                    
                    <transition name="slide-fade" mode="out-in">
                        <router-view v-on:hideHeaderFooter="hideHeaderFooter"
                        v-on:showHeaderFooter="showHeaderFooter"
                        v-on:logoutUser="logoutUser" />
                    </transition>

                </div>

            </div>

            <!-- <div class="languages-box">
               <ListaIdioma></ListaIdioma>
           </div> -->

            <!-- <div class="box-Marquee" v-if="exibir">
                <MarqueePrices></MarqueePrices>
            </div> -->
            <!-- <appFooter v-if="exibir" v-on:adjustFooterPadding="adjustFooterPadding" /> -->
        </main>

    </div>
</template>
<script>
    import HeaderBar from "@/components/HeaderBar/HeaderBar.vue";
    import SideBar from "@/components/SideBar/SideBar.vue";
    //import MenuBar from "@/components/MenuBar/MenuBar.vue";
    //import appFooter from "@/components/AppFooter/appFooter.vue";
    //import MarqueePrices from "@/components/MarqueePrices/marqueePrices.vue";
    //import ListaIdioma from '@/components/ListaIdioma/ListaIdioma.vue';

    export default {
        name: "app",
        components: {
            HeaderBar,
            SideBar
            //ListaIdioma,
            //MarqueePrices,
            //MenuBar,
            //appFooter
        },
        data() {
            return {
                exibir: false,
                idUsuario: null,
                nomeUsuario: "",
                dismissCountDown: 5,
                paddingBottom: String(),
                emAcessoRemoto: false,
                publicPages: [
                    "/index",
                    "/login",
                    "/register",
                    "/offline",
                    "/logout",
                    "/reset-password",
                    "approve-wallet",
                    "/confirm-email",
                    "/financial-report"
                    
                ],
                hideHeaderFooterPages: [
                    "/login",
                    "/offline",
                    "/register",
                    "/logout",
                    "/reset-password",
                    "/confirm-email",
                    "/financial-report"
                ]
            };
        },
        computed: {
            alert() {
                return this.$store.state.alert;
            }
        },
        beforeMount() {
            if (
                this.hideHeaderFooterPages.filter(p => this.$route.path.indexOf(p) != -1)
                    .length > 0
            )
                this.exibir = false;
            else this.exibir = true;

            //this.exibir = false

            let dadosUsuario = this.$store.getters["autenticacao/getLogin"].user;
            let acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
            if (dadosUsuario && acessoRemoto) this.emAcessoRemoto = acessoRemoto;

            if (
                dadosUsuario == null && !(this.publicPages.filter(p => this.$route.path.indexOf(p) != -1).length >0)
            )
                window.location.href = "/#/index";
            else if (dadosUsuario != null) {
                this.idUsuario = dadosUsuario.id;
                this.nomeUsuario = dadosUsuario.username;
            }
        },
        methods: {
            hideHeaderFooter() {
                this.exibir = false;
            },
            showHeaderFooter() {
                this.exibir = true;
            },
            adjustFooterPadding(heightFooter) {
                this.paddingBottom = `${heightFooter + 40}px`;
            },
            logoutUser() {
                this.idUsuario = null;
                this.nomeUsuario = "";
            }
        }
    };
</script>