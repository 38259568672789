import http from '@/_helper/api-services';
import "./SideBar.scss";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";

export default {
    name: 'SideBar',

    data() {
        return {
            dadosUsuario: {},
            resumoValores: {},
            dadoLogado: {},
            linkWhats: String(),
            searchMenu: Boolean()
        }
    },
    components: {
        LinkPatrocinador
    },
    mounted() {
        
    },
    beforeMount() {
        this.obterResumoValores();
        // this.obterDadosUsuario();
        this.obterNomeLogado();
        this.getLinkWhats();
    },
    methods: {
        getLinkWhats(){
            http.get("/general/getWhatsapp").then(success => {
                this.linkWhats = success.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
            });
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString();
                // return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            } else {
                return "";
            }
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return this.casasDecimais(valor,2).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        formatPercentage(value) {
            if(!value)
                return '0.00%';
            return value.toFixed(2) + '%';
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
        // obterDadosUsuario() {
        //     const loggedIn = localStorage.getItem("user");
        //     if (loggedIn) {
        //         this.$loading(true);
        //         http.get('/Dashboard/getBarStatus/').then((responde) => {
        //             this.dadosUsuario = responde.data;
        //             this.operacaoAutomatica = this.dadosUsuario.operacaoAutomatica;
        //             this.diaSelecionado = this.dadosUsuario.diaConsultaSaldo;

        //             this.totalUsers = this.dadosUsuario.totalPessoas;
        //             //this.changeClass();

        //             //this.obterLucroEGanho(this.dadosUsuario.diaConsultaSaldo);
        //         },error => {
        //             if (error.response && error.response.data && error.response.data.errors)
        //                 error.response.data.errors.forEach(e => {
        //                     this.$snotify.error(e);
        //                 });
        //             // else
        //             //     this.$snotify.error("An unexpected error has occurred");
        //         });
        //     }

        //     const forceCountry = localStorage.getItem("force-country");
        //     if (forceCountry) {
        //         this.$bvModal.show('modal-pais');
        //     }
        // },
        obterResumoValores() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/Dashboard/summariesValues/').then((responde) => {
                    this.resumoValores = responde.data;
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
        casasDecimais(num, precisao) {
            var casas = Math.pow(10, precisao);
            return Math.floor(num * casas) / casas;
        }
    }
}