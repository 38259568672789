import "./CardInvestimento.scss";
import http from '@/_helper/api-services';

export default {
    name: 'CardInvestimento',
    props: ['resumoValores', 'limiteAtingido'],
    data() {
        return {
            dadosBarraStatus: {},
            divisao: 'Divisão de lucros',
            investiment: {}
        }
    },
    beforeMount() {
        this.withdrawals();
    },
    mounted() {
        //this.obterBarraStatus();
    },
    methods: {
        // obterBarraStatus() {
        //     this.$loading(true);
        //     http.get('/Dashboard/getBarStatus/').then((responde) => {
        //         this.dadosBarraStatus = responde.data;
        //         this.$loading(false);
        //     },error => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         // else
        //         //     this.$snotify.error("An unexpected error has occurred");
        //     });
        // },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        withdrawals(){
            http.get("/Arbitragem/getBalance").then((response) => {
                this.investiment = response.data;
            }, (error) =>{
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                )
                    error.response.data.errors.forEach((m) =>
                        this.$snotify.error(m)
                    );
            })
        }
    }
}