import "./HeaderBar.scss";
import apiService from "@/_helper/api-services.js";
import UserInformation from "@/components/UserInformation/UserInformation.vue";
import MenuMobile from "@/components/MenuMobile/MenuMobile.vue";
import MenuBarHorizontal from "@/components/MenuBarHorizontal/MenuBarHorizontal.vue";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";

export default {
    name: "HeaderBar",
    data() {
        return {
            // menus: Array(),
            acessoRemoto:false,
        };
    },
    components: {
        UserInformation,
        MenuMobile,
        MenuBarHorizontal,
        LinkPatrocinador
    },
    props: {
        searchMenu: Boolean(),
        idUsuario: null
    },
    beforeMount() {
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
        // if (this.$props.searchMenu) {
        //     if (localStorage.getItem("menu"))
        //         this.menus = JSON.parse(localStorage.getItem("menu")).filter(u => !u.rotaPublica);
        //     else
        //         apiService.get("/general/getMenu").then(success => {
        //             if (success.data) {
        //                 this.menus = success.data.filter(u => !u.rotaPublica);
        //                 localStorage.setItem("menu", JSON.stringify(success.data));
        //             }
        //             else
        //                 this.$snotify.error("An unexpected error has occurred");
        //         }, error => {
        //             if (error && error.response && error.response.data)
        //                 this.$snotify.error("An unexpected error has occurred");
        //         });
        // }

    }
}