import http from '@/_helper/api-services'
import './ExtratoBancario.scss'
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";
import DatePicker from 'vue2-datepicker';
// import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
// import CardInvestimento from '@/components/CardInvestimento/CardInvestimento.vue';

export default {
    name: 'ExtratoBancario',
    data() {
        return {
            isActive: false,
            locale: 'pt-BR',
            search: '',
            nomeLogin: '',
            fields: [
                { key: 'tipo', label: this.$i18n.t('financeiro.tipo'), sortable: true, sortDirection: 'desc' },
                { key: 'data', label: this.$i18n.t('financeiro.data'), sortable: true, class: 'text-center' },
                { key: 'descricao', label: this.$i18n.t('financeiro.descricao'), sortable: true, class: 'text-center' },
                { key: 'valor', label: this.$i18n.t('financeiro.valor'), sortable: true, class: 'text-center' }],
            dadosResumoSaldo: {},
            formSearch: {
                dataInicio: this.dataMenos15(),
                dataFim: new Date(),
                tipoFilter: null,
                itensPorPagina: 25,
                paginaAtual: 1,
                chave: null,
                texto: ''
            },
            tipoExtrato:"rede",
            listaExtrato:[
                {text: this.$i18n.t("financeiro.extrato_rede"), value:"rede"},
                {text: this.$i18n.t("financeiro.extrato_operacao"), value:"rendimento"}
                // {text: this.$i18n.t("financeiro.extrato_moeda"), value:"moeda"},
            ],
            items: [],
            totalPaginas: 1,
            currentPage: 1,
            showIconsToChangePage: false,
            dadosResumoCashBack: {},
            dadosUsuario: {},
            listDados: [],
            listTipoFiltro: [],
            totalRows: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            resumoValores: {},
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            }
        }
    },
    components: {
        LinkPatrocinador,
        DatePicker,
        // CardInvestimento
    },
    beforeMount() {
        // this.obterDadosUsuario();
    },
    mounted() {
        //this.obterNomeLogado();
        //this.$loading(true);
        this.listarDados();
        //this.tipoFiltro();
        //this.obterResumoValores();
        //this.resumoSaldo();
    },
    filters: {
        formatMoeda: function (valor) {
            return "USDT$ " + parseFloat(valor).toFixed(2).replace(".", "");
        },
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        totalPagesToDisplay() {
            if (this.totalPaginas <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPaginas;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPaginas - this.formSearch.paginaAtual;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPaginas - 2; i <= this.totalPaginas; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPaginas - 1) return [1, 2, 3];
                else return [this.formSearch.paginaAtual - 1, this.formSearch.paginaAtual, this.formSearch.paginaAtual + 1];
            }
        }
    },
    methods: {
        // mudarExtrato(){
        //     if(this.tipoExtrato == "rede"){
        //         this.listarDados();
        //     }else {
        //         this.listarDadosRendimento();
        //     }
        // },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        alterarPagina(pagina) {
            this.formSearch.paginaAtual = pagina;
            this.listarDados();
        },
        // filterClick(tipoExtrato) {
        //     if(tipoExtrato == 'rede'){
        //         this.formSearch.paginaAtual = 1;
        //         this.listarDados();
        //     }
        //     else{
        //         this.listarDadosRendimento();
        //     }
        // },
        // obterDadosUsuario() {
        //     this.$loading(true);
        //     http.get('/Dashboard/getBarStatus/').then((responde) => {
        //         this.dadosUsuario = responde.data;
        //         this.$loading(false);
        //     },error => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         // else
        //         //     this.$snotify.error("An unexpected error has occurred");
        //     });
        // },
        filterClick() {
            this.formSearch.paginaAtual = 1;
            //this.listarDados();
            if(this.tipoExtrato == "rede"){
                this.listarDados();
            }else {
                this.listarDadosRendimento();
            }
        },
        // listarDadosMoeda() {
        //     this.$loading(true);
        //     http.post('/extract/moneyStatement', this.formSearch).then((response) => {
        //         this.listDados = [];
        //         //this.totalPaginas = response.data.totalPages;
        //         response.data.lancamentos.forEach(item => {
        //             this.listDados.push({
        //                 tipo: item.tipo,
        //                 data: item.dataLancamento,
        //                 descricao: item.descricao,
        //                 valor: item.valor.toFixed(2),
        //                 valorFormated: '$ ' + item.valor.toFixed(2)
        //             })
        //         });
        //         this.totalRows = this.listDados.lenght;
        //         this.$loading(false);
        //     },
        //     error => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         else
        //             this.$snotify.error("An unexpected error has occurred");
        //         this.$loading(false);
        //     });
        // },
        listarDadosRendimento() {
            this.$loading(true);
            http.post('/extract/incomeStatement', this.formSearch).then((response) => {
                this.listDados = [];
                //this.totalPaginas = response.data.totalPages;
                response.data.lancamentos.forEach(item => {
                    this.listDados.push({
                        tipo: item.tipo,
                        data: item.dataLancamento,
                        descricao: item.descricao,
                        valor: item.valor.toFixed(2),
                        valorFormated: '$ ' + item.valor.toFixed(2)
                    })

                });
                this.totalRows = this.listDados.length;
                this.$loading(false);
            },
            error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
                this.$loading(false);
            });
        },
        listarDados() {
            this.$loading(true);
            http.post('/extract/fetchExtract', this.formSearch).then((response) => {
                this.listDados = [];
                //this.totalPaginas = response.data.totalPages;
                response.data.lancamentos.forEach(item => {
                    this.listDados.push({
                        tipo: item.tipo,
                        data: item.dataLancamento,
                        descricao: this.translate(item.tipo,item.descricao),
                        valor: item.valor.toFixed(4),
                        valorFormated: '$ ' + item.valor.toFixed(4),
                        ativo: item.ativo
                    })
                });
                this.totalRows = this.listDados.length;
                this.$loading(false);
            },
            error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
                this.$loading(false);
            });
        },
        translate(tipo, descricao){
            if(tipo == 'Pagamento Direto' || tipo == 'Pagamento Indireto'){
                let result = descricao.replace('nivel',this.$i18n.t("premios.nivel"));
                return result;
            }
            if(tipo == 'Crédito Prêmiação'){
                let result = this.$i18n.t("graduacao."+descricao);
                return result;
            }
            if(tipo == 'Distribuição'){
                if(descricao.includes("credito pedido saldo")){
                    let result = descricao.replace('credito pedido saldo',this.$i18n.t("financeiro.credito_premiacao_saldo"));
                    return result;
                }
                else{
                    let result = descricao.replace('credito pedido',this.$i18n.t("financeiro.credito_premiacao"));
                    return result;
                }
            }
            if(tipo == 'Bonus Presidente'){
                let result = descricao.replace('nivel',this.$i18n.t("premios.nivel"));
                return result;
            }
            return descricao;
        },
        obterResumoValores() {
            http.get('/Dashboard/summariesValues/').then((responde) => {
                this.resumoValores = responde.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        resumoSaldo() {
            http.get('/extract/summaryBalance').then((responde) => {
                this.dadosResumoCashBack = responde.data.filter(x => x.tipoSaldo == "CashBack")[0];
                this.dadosResumoSaldo = responde.data.filter(x => x.tipoSaldo == "SaldoRede")[0];
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        tipoFiltro() {
            http.get('/general/statement-types').then((responde) => {
                
                this.listTipoFiltro.push({
                    value: null,
                    text: this.$i18n.t("tipos.selecione")
                })
                responde.data.forEach(item => {
                    
                    this.listTipoFiltro.push({
                        value: item.chave,
                        text: this.$i18n.t('tipos.' + item.descricao)
                    });
                });
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        dataMenos15() {
            var result = new Date();
            result.setDate(result.getDate() + -15);
            return result;
        },
        formatDate(data) {
            let dateTime = new Date(data);
            return dateTime.toLocaleDateString();
            //return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
        },
    }
}