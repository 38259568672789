import http from '@/_helper/api-services';

export default {
    name: 'MeusDados',
    data() {
        return {
            fields: [
                { key: "descricao", label: this.$i18n.t("financeiro.descricao"), sortable: true },
                { key: "endereco", label: this.$i18n.t("meus_dados.endereco"), sortable: true },
                { key: "moeda", label: this.$i18n.t("saque.tipo_wallet"), sortable: true },
                { key: "aprovado", label: this.$i18n.t("financeiro.aprovado"), sortable: true },
                { key: "dataAprovacao", label: this.$i18n.t("saque.data_aprovado"), sortable: true },
                { key: "opcoes", label: this.$i18n.t("generico.opcoes"), sortable: false },
            ],
            items: [],
            acessoRemoto: false,
            usuarioCarteira: {
                endereco: null,
                descricao: String(),
                moeda: null,
                tipoChavePix: null
            },
            carteiras: [],
            resultWallet: String(),
            tipoWallet: [
                { text: this.$i18n.t("saque.escolha_tipo"), value: null },
                // { text: "BTC", value: "BTC" },
                { text: "USDT(TRC20)", value: "USDT(TRC20)" },
                { text: "PIX", value: "PIX" }
            ],
            selectChavePix: [
                { text: this.$i18n.t("saque.escolha_tipo"), value: null },
                { text: this.$i18n.t("cadastro.celular"), value: "phone" },
                { text: this.$i18n.t("cadastro.email"), value: "email" },
                { text: "CPF", value: "cpf" },
                { text: "CNPJ", value: "cnpj" },
                { text: this.$i18n.t("cadastro.chave_aleatoria"), value: "token" }
            ],
            address: null,
            isPix: false
        }
    },
    props: ['erroForm'],
    components: {
    },
    watch: {
        "usuarioCarteira.moeda"() {
          if (this.usuarioCarteira.moeda == "PIX")
            this.isPix = true;
          else {
            this.isPix = false;
          }
        },
      },
    beforeMount() {
     //this.meioPagamentoUser();
    },
    mounted() {
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
        this.buscarCarteiras();
    },
    methods: {
        removeEspecialCharacter() {
            this.login = this.login.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        },
        validateLogin(event) {
            if (/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(event.key) || event.key == " ")
                event.preventDefault();
        },
        meioPagamentoUser(){
            http.get("/walletUser/user-wallet").then((response) => {
                if(response.data.length > 0){
                    if(response.data == 'PIX'){
                        this.tipoWallet.push(
                            {
                                value: response.data,
                                text: response.data
                            }
                        );
                    }
                    else{
                        this.tipoWallet.push({ text: "USDT(TRC20)", value: "USDT(TRC20)" });
                    }

                }
            });
        },
        buscarCarteiras() {
            this.$loading(true);
            http.get("/walletUser/getUserWallet").then((response) => {
                this.carteiras = response.data;
                this.preencherListaCarteiras();
                this.$loading(false);
            });
        },
        async verifyWallet(WalletAddress) {
            this.address = WalletAddress
            await http.get(`/withdraw/validateBTC/${this.address}`).then((response) => {
                this.resultWallet = response.data.result.code;
            });
            return this.resultWallet;
        },
        async validateWallet() {
            if (this.usuarioCarteira.moeda == "BTC") {
                let value = await this.verifyWallet(this.usuarioCarteira.endereco);
                if (value == 1000) {
                    this.$snotify.error(this.$i18n.t("erros.carteira_invalida"));
                    return;
                }
                else this.salvarCarteira();
            }
            else if (this.usuarioCarteira.moeda == null) {
                this.$snotify.error(this.$i18n.t("erros.preencha_os_campos"));
            }
            else {
                this.salvarCarteira();
            }
        },
        checkFormCarteira() {
            if (!this.usuarioCarteira.endereco || !this.usuarioCarteira.descricao) {
                this.erroForm = true;
                this.$snotify.error($t("erros.preencha_os_campos_senha"));

                return false;
            }
            else if(this.usuarioCarteira.moeda == 'PIX' && !this.usuarioCarteira.tipoChavePix){
                this.erroForm = true;
                this.$snotify.error(this.$i18n.t("erros.carteira_invalida"));
                return false
            }

            return true;
        },
        formatDate(date, showTime) {
            if (!showTime)
                return new Date(date).toLocaleDateString();
            else return `${new Date(date).toLocaleDateString()} - ${new Date(date).toLocaleTimeString()}`
        },
        salvarCarteira() {
            if (this.acessoRemoto) {
                this.$snotify.error(this.$i18n.t("generico.aviso_acesso_remoto"));
                return;
            }
            if (this.checkFormCarteira()) {
                this.$loading(true);
                http
                    .post("/walletUser/registerWalletUser", this.usuarioCarteira)
                    .then(
                        (response) => {
                            this.$snotify.success(response.data.message);
                            this.usuarioCarteira.endereco = null;
                            this.usuarioCarteira.descricao = null;
                            this.usuarioCarteira.moeda = null;
                            this.usuarioCarteira.tipoChavePix = null;
                            this.buscarCarteiras();
                        },
                        (error) => {
                            if (
                                error.response &&
                                error.response.data &&
                                error.response.data.errors
                            )
                                error.response.data.errors.forEach((m) =>
                                    this.$snotify.error(m)
                                );
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        preencherListaCarteiras() {
            this.items = [];

            if(this.carteiras){
                this.carteiras.forEach((item) => {
                    this.items.push({
                        idUsuarioCarteira: item.idUsuarioCarteira,
                        descricao: item.descricao,
                        endereco: item.endereco,
                        moeda: item.moeda,
                        aprovado: item.aprovado,
                        dataAprovacao: item.dataAprovacao,
                        status: item.ativo
                    });
                });
            }
        },
        ativarDesativar(idUsuarioCarteira) {
            http.get("/User/checkStatus").then((response) => {
                this.$loading(true);
                let obj = {
                    idUsuarioCarteira: idUsuarioCarteira
                };

                http.update('/walletUser/activateDeactivateWallet', obj).then((response) => {
                    this.$snotify.success(response.data.message);
                    this.items = this.items.filter(item => item.idUsuarioCarteira !== idUsuarioCarteira);
                }, error => {
                    if (error.response && typeof error.response.data == "object")
                        this.$snotify.error(error.response.data.message);
                }).finally(() => {
                    this.$loading(false);
                });
            },
                error => {
                    this.$snotify.error(error.response.data.message);
                });
        }
    }
}