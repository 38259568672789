import http from "@/_helper/api-services.js";

export default {
    name: "HistoricoTransferencia",
    data() {
        return {
            fields: [
                { key: "dataLancamento", label: this.$i18n.t("transferencia.data_lancamento"), sortable: true, },
                { key: "status", label: this.$i18n.t("transferencia.status"), sortable: true },
                { key: "tipo", label: this.$i18n.t("transferencia.tipo"), sortable: true },
                { key: "descricao", label:  this.$i18n.t("transferencia.descricao"), sortable: true },
                { key: "valor", label: this.$i18n.t("transferencia.valor"), sortable: true }
            ],
            history: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        };
    },
    props: ['historicoAtualizado'],
    watch: {
        historicoAtualizado(el) {
            this.obterHistoricoTransferencia();
        }
    },
    beforeMount() {
        this.obterHistoricoTransferencia();
    },
    methods: {
        obterHistoricoTransferencia() {
            this.$loading(true);

            http.get("/transfer/getTransfers").then(success => {
                this.history = [];

                if (success.data) {
                    success.data.forEach(d => {
                        this.history.push({
                            dataLancamento: d.dataLancamento,
                            status: d.status,
                            tipo: d.tipo,
                            valor: d.valor,
                            descricao: d.descricao
                        });
                    });
                }
                this.$loading(false);
            }, error => { });
        },
        formatDate(data) {
            let dateTime = new Date(data);
            return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
        },
    }
}