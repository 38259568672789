import http from '@/_helper/api-services'
import './PlanoCarreira.scss'

export default {
    name: 'PlanoCarreira',
    data() {
        return {
        }
    },
    components: {
    },
    beforeMount(){
    },
    methods:{
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
    }
}