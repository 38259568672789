import http from "@/_helper/api-services.js";
import "./GerenciarCampeonatos.scss";

export default {
    name: "GerenciarCampeonatos",
    data() {
        return {
            list: [],
            perPage: 50,
            currentPage: 1,
            totalRows: 1,
            fields: [
                { key: "name", label: "Campeonato", headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Campeonato', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' }, sortable: true },
                { key: "ativo", label: "Ativo", sortable: true, filterByFormatted: true, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Editar Ativo', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "country", label: "País", headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Home Team', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' }, sortable: true },
                { key: "acoes", label: "Ações", sortable: true, sortDirection: "desc", headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Editar Odd Home', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } }
            ],
            pageOptions: [50, 100, 150, {
                value: 99999,
                text: "show all"
            }],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            action: "",
            filterOn: [],
            filtro: {
                ativo: null,
                name: String(),
                country: String()
            },
            opcoesAtivo: [
                { text: 'Escolha uma opção', value: null },
                { text: 'Ativo', value: true },
                { text: 'Inativo', value: false },
            ],
            // opcoesCasas: [
            //     { text: 'Escolha a casa', value: null },
            //     { text: '1xBet', value: '1xBet' },
            //     { text: 'Betfair', value: 'Betfair' },
            //     { text: 'KTO', value: 'KTO' },
            //     { text: 'Betcris', value: 'Betcris' },
            //     { text: 'NordicBet', value: 'NordicBet' },
            //     { text: 'Bet365', value: 'Bet365' },
            //     { text: 'Betano', value: 'Betano' },
            //     { text: 'Betway', value: 'Betway' },
            //     { text: 'Unibet', value: 'Unibet' },
            //     { text: 'Sportingbet', value: 'Sportingbet' },
            // ],
            opcoesCampeonatos: [
                { text: 'Escolha o campeonato', value: null }
            ],
        };
    },
    beforeMount() {
        this.buscarCampeonatos();
    },
    mounted() {
    },
    methods: {
        buscarCampeonatos() {
            this.$loading(true);
            http.post("/admin/getCompetitions", this.filtro).then(
                (success) => {
                    this.list = success.data;
                    this.totalRows = this.list.length;

                    const campeonatosPorTipo = Object.groupBy(success.data, ({ country }) => country);
                    const campeonatos = Object.keys(campeonatosPorTipo);
                    
                    campeonatos.forEach(a => {
                        this.opcoesCampeonatos.push({
                            text: a,
                            value: a
                        })
                    })
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                }
            );
        },
        ativarDesativarCampeonato(idSport) {
            let obj = {
                idSport: idSport
            };
            http.update('/admin/activateDeactivateCompetition', obj).then((response) => {
                this.$snotify.success("Atualização efetuada com sucesso");
                this.buscarCampeonatos();
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        formataAtivo(valor) {
            return valor ? 'Sim' : 'Não';
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        }
    },
};