import http from "@/_helper/api-services.js";
import DatePicker from 'vue2-datepicker';
import util from "@/_helper/util.js";
import { Money } from "v-money";
import "./gerenciarJogos.scss";

export default {
    name: "gerenciarJogos",
    data() {
        return {
            list: [],
            perPage: 50,
            currentPage: 1,
            totalRows: 1,
            fields: [
                { key: "sportTitle", label: "Campeonato", headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Campeonato', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' }, sortable: true },
                { key: "homeTeam", label: "Home Team", headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Home Team', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' }, sortable: true },
                { key: "awayTeam", label: "Away Team", headerClass: 'text-center', class: 'text-center', sortable: true, sortDirection: "desc", width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Away Team', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "dataJogo", label: "Data Jogo", headerClass: 'text-center', class: 'text-center', sortable: true, sortDirection: "desc", width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Data Jogo', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "oddHome", label: "Odd Home", sortable: true, filterByFormatted: true, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Odd Home', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "casaHome", label: "Casa Home", sortable: true, filterByFormatted: true, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Casa Home', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "oddDraw", label: "Odd Draw", sortable: true, filterByFormatted: true, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Odd Draw', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "casaDraw", label: "Casa Draw", sortable: true, filterByFormatted: true, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Casa Draw', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "oddAway", label: "Odd Away", sortable: true, filterByFormatted: true, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Odd Away', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "casaAway", label: "Casa Away", sortable: true, filterByFormatted: true, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Casa Away', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "percentage", label: "Percentage", sortable: true, filterByFormatted: true, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Percentage', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "ativo", label: "Ativo", sortable: true, filterByFormatted: true, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Ativo', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "idCategoria", label: "Categoria", sortable: true, filterByFormatted: true,formatter: value => { return this.$t("jogos.categoria_" + value); }, headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Categoria', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } },
                { key: "acoes", label: "Ações", sortable: true, sortDirection: "desc", headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Editar Odd Home', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' } }
            ],
            fieldsSelecionados: [
                { key: "login", label: "Login", sortable: true, sortDirection: "desc" },
                { key: "dataSolicitacao", label: "Data Saque", sortable: true },
                {
                    key: "valorReal",
                    label: "Valor a receber",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `$ ${value.toFixed(2)}`;
                    },
                },
            ],

            list: [],
            valorSelecionado: 0,
            selected: [],
            perPage: 50,
            pageOptions: [50, 100, 150, {
                value: 99999,
                text: "show all"
            }],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            action: "",
            filterOn: [],
            todosSelecionados: false,
            filtro: {
                ativo: null,
                homeTeam: String(),
                awayTeam: String(),
                sportTitle: null,
                dataJogo: new Date(),
                idCategoria: 1
            },
            opcoesAtivo: [
                { text: 'Escolha uma opção', value: null },
                { text: 'Ativo', value: true },
                { text: 'Inativo', value: false },
            ],
            opcoesCategoria: [
                { text: 'Todos', value: 1 },
                { text: 'Pix', value: 2 },
                { text: 'Cripto', value: 3 },
            ],
            opcoesCasas: [
                { text: 'Escolha a casa', value: null },
                { text: '1xBet', value: '1xBet' },
                { text: 'Betfair', value: 'Betfair' },
                { text: 'KTO', value: 'KTO' },
                { text: 'Betcris', value: 'Betcris' },
                { text: 'NordicBet', value: 'NordicBet' },
                { text: 'Bet365', value: 'Bet365' },
                { text: 'Betano', value: 'Betano' },
                { text: 'Betway', value: 'Betway' },
                { text: 'Unibet', value: 'Unibet' },
                { text: 'Sportingbet', value: 'Sportingbet' },
            ],
            opcoesCampeonatos: [
                { text: 'Escolha o campeonato', value: null }
            ],
            editJogo: {
                idJogo: 0,
                oddHome: 0,
                casaHome: null,
                oddDraw: 0,
                casaDraw: null,
                oddAway: 0,
                casaAway: null,
                percentage: 0,
                commenceTime: new Date(),
                homeTeamScore: 0,
                awayTeamScore: 0,
                idCategoria: 1
            },
            finalizaJogo: {
                idJogo: 0
            },
            money: {
                decimal: ".",
                thousands: ",",
                precision: 2,
                masked: false,
                maxlength: 5
            },
            percentage: {
                decimal: ",",
                thousands: ".",
                suffix: "%",
                precision: 2,
                masked: false,
                maxlength: 6
            },
            score:{
                precision: 0,
                masked: false,
                maxlength: 5
            },
            game:{
                homeTeam: null,
                awayTeam: null
            },
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",],
            },
        };
    },
    components: {
        Money,
        DatePicker
    },
    computed: {
        oddHomeState() {
            return this.editJogo.oddHome.length > 2 ? true : false;
        },
        oddDrawState() {
            return this.editJogo.oddDraw.length > 2 ? true : false;
        },
        oddAwayState() {
            return this.editJogo.oddAway.length > 2 ? true : false;
        },
        percentageState() {
            return this.editJogo.percentage.length > 2 ? true : false;
        },
        scoreHomeState() {
            return this.editJogo.homeTeamScore.length > 0 ? true : false;
        },
        scoreAwayState() {
            return this.editJogo.awayTeamScore.length > 0 ? true : false;
        },
    },
    beforeMount() {
        this.buscarJogos();
        this.listarCampeonatos();
    },
    mounted() {
    },
    watch: {
        perPage() {
            this.search.page = 1;
            this.buscarJogos();
        },
        currentPage() {
            this.buscarJogos();
        },
    },
    methods: {
        dataMenos15() {
            var result = new Date();
            result.setDate(result.getDate() + -15);
            return result;
        },
        listarCampeonatos() {
            http.get('/jogo/listCompetitions').then((response) => {
                response.data.forEach(a => {
                    this.opcoesCampeonatos.push({
                        text: a.name + ' ' + a.country,
                        value: a.name
                    });

                });
            },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                })
        },
        abriModalFinalizarJogo(item){
            this.game.homeTeam = item.homeTeam;
            this.game.awayTeam = item.awayTeam;

            this.finalizaJogo.idJogo = item.idJogo;
            this.$bvModal.show("modal-finalizarJogo");
        },
        abriModalEditarJogo(item) {
            this.game.homeTeam = item.homeTeam;
            this.game.awayTeam = item.awayTeam;

            this.editJogo.commenceTime = item.dataJogo;
            this.editJogo.homeTeamScore = item.homeTeamScore;
            this.editJogo.awayTeamScore = item.awayTeamScore;
            this.editJogo.idJogo = item.idJogo;
            this.editJogo.oddHome = item.oddHome;
            this.editJogo.oddDraw = item.oddDraw;
            this.editJogo.oddAway = item.oddAway;
            this.editJogo.casaHome = item.casaHome;
            this.editJogo.percentage = item.percentage;
            this.editJogo.casaHome = item.casaHome;
            this.editJogo.casaDraw = item.casaDraw;
            this.editJogo.casaAway = item.casaAway;
            this.editJogo.idCategoria = item.idCategoria;

            this.$bvModal.show("modal-editarJogo");
        },
        editarPorcentagemJogo() {

            http.post("/admin/editGamePercentage", this.editJogo).then(
                (success) => {
                    this.fecharModal();
                    this.list = [];
                    this.$snotify.success("Atualização efetuada com sucesso");
                    this.buscarJogos();
                    //this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                }
            );
        },
        finalizarJogo(){
            // const newDate = new Date(this.finalizaJogo.commenceTime);
            // newDate.setHours(newDate.getHours() + (-3));
            // this.finalizaJogo.commenceTime = newDate;
            
            http.post("/jogo/endGame/" + this.finalizaJogo.idJogo).then(
                (success) => {
                    this.buscarJogos();
                    this.fecharModalFinalizar();
                    this.$snotify.success("Atualização efetuada com sucesso");
                },
                (error) => {
                    this.$loading(false);
                }
            );
        },
        buscarJogos() {
            this.$loading(true);
            http.post("/admin/getJogos", this.filtro).then(
                (success) => {
                    this.list = success.data.jogos;
                    this.totalRows = this.list.length;
                },
                (error) => {
                    this.$loading(false);
                }
            ).finally(() => { this.$loading(false); });;
        },
        limpaEdicao(){
            this.editJogo.idJogo = 0,
            this.editJogo.oddHome = 0,
            this.editJogo.casaHome = null,
            this.editJogo.oddDraw = 0,
            this.editJogo.casaDraw = null,
            this.editJogo.oddAway = 0,
            this.editJogo.casaAway = null,
            this.editJogo.percentage = 0,
            this.editJogo.commenceTime = new Date(),
            this.editJogo.homeTeamScore = 0,
            this.editJogo.awayTeamScore = 0
        },
        ativarDesativarJogo(idJogo) {
            let obj = {
                idJogo: idJogo
            };
            http.update('/admin/activateDeactivateGame', obj).then((response) => {
                this.$snotify.success("Atualização efetuada com sucesso");
                this.buscarJogos();
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        jogosEmDestaque(idJogo) {
            let obj = {
                idJogo: idJogo
            };
            http.post('/admin/featuredGames', obj).then((response) => {
                this.$snotify.success("Atualização efetuada com sucesso");
                this.buscarJogos();
            }, error => {
                if (error.response && typeof error.response.data == "object")
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        formataDecimal(valor) {
            return valor.toFixed(2);
        },
        formataAtivo(valor) {
            return valor ? 'Sim' : 'Não';
        },
        verificarUsuario() {
            http.get("/admin/checkUserAdmin").then((response) => {
                this.openModalPagamentoManual();
            },
                (error) => {
                    this.$snotify.error(error.response.data);
                });
        },

        fecharModal() {
            //this.atualizaDados();
            this.limpaEdicao();
            this.$bvModal.hide("modal-editarJogo");
        },
        fecharModalFinalizar() {
            this.$bvModal.hide("modal-finalizarJogo");
        },
        openModal(tipo) {
            this.action = tipo;
            this.$bvModal.show("modal-confirm");
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        formatDate(data) {
            return util.formatDate(data);
        },
        formatPorcentagem(value) {
            let val = (value / 1).toFixed(2)
            return val.toString().replace(/[.]/g, ",")
        },
        formatOdd(val) {
            return val.toFixed(2);
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
    },
};