import "./MenuBarHorizontal.scss";
import apiService from "@/_helper/api-services.js";
import ListaIdiomaSite from '@/components/ListaIdiomaSite/ListaIdioma.vue';

export default {
    name: "MenuBarHorizontal",
    data() {
        return {
            menus: Array(),
            acessoRemoto:false,
            nomeLogin: '',
            linkWhats: String()
        };
    },
    components: {
        ListaIdiomaSite
    },
    props: {
        searchMenu: Boolean(),
        idUsuario: null
    },
    beforeMount() {
        this.obterNomeLogado();
        this.getLinkWhats();
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
        if (this.$props.searchMenu) {
            if (localStorage.getItem("menu"))
                this.menus = JSON.parse(localStorage.getItem("menu")).filter(u => !u.rotaPublica);
            else
                apiService.get("/general/getMenu").then(success => {
                    if (success.data) {
                        this.menus = success.data.filter(u => !u.rotaPublica);
                        localStorage.setItem("menu", JSON.stringify(success.data));
                    }
                    // else
                        // this.$snotify.error("An unexpected error has occurred");
                }, error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                });
        }

    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
        getLinkWhats(){
            apiService.get("/general/getWhatsapp").then(success => {
                this.linkWhats = success.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
            });
        }
    }
}