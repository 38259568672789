import http from '@/_helper/api-services'
import './PagarPedido.scss'
import SideBar from "@/components/SideBar/SideBar.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import DatePicker from 'vue2-datepicker';
import CardInvestimento from '@/components/CardInvestimento/CardInvestimento.vue';

export default {
    name: 'PagarPedido',
    data() {
        return {
            isActive: false,
            locale: 'en',
            search: '',
            nomeLogin: '',
            fields: [
                { key: 'tipo', label: this.$i18n.t('financeiro.tipo'), sortable: true, sortDirection: 'desc' },
                { key: 'data', label: this.$i18n.t('financeiro.data'), sortable: true, class: 'text-center' },
                { key: 'descricao', label: this.$i18n.t('financeiro.descricao'), sortable: true, class: 'text-center' },
                { key: 'valor', label: this.$i18n.t('financeiro.valor'), sortable: true, class: 'text-center' }],
            dadosResumoSaldo: {},
            form: {
                codigo:'',
                tipoSaldo: "rede"
            },
            listTipoSaldo: [
                
                {text: this.$i18n.t("pagar_pedido.saldo_ativacao"), value:"ativacao"},
            ],
            items: [],
            totalPaginas: 1,
            currentPage: 1,
            showIconsToChangePage: false,
            dadosResumoCashBack: {},
            // dadosUsuario: {},
            listDados: [],
            totalRows: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            resumoValores: {},
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            detalhesPedido: {}
        }
    },
    components: {
        SideBar,
        PremiosDashboard,
        DatePicker,
        CardInvestimento
    },
    beforeMount() {
        // this.obterDadosUsuario();
    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(true);
        //this.listarDados();
        //this.tipoFiltro();
        //this.obterResumoValores();
        //this.resumoSaldo();
    },
    filters: {
        formatMoeda: function (valor) {
            return "USDT$ " + parseFloat(valor).toFixed(2).replace(".", ",");
        },
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        totalPagesToDisplay() {
            if (this.totalPaginas <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPaginas;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPaginas - this.formSearch.paginaAtual;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPaginas - 2; i <= this.totalPaginas; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPaginas - 1) return [1, 2, 3];
                else return [this.formSearch.paginaAtual - 1, this.formSearch.paginaAtual, this.formSearch.paginaAtual + 1];
            }
        }
    },
    methods: {
        checkForm(){
            if(!this.form.codigo){
                this.erroForm = true;
                this.$snotify.error(this.$t("erros.preencha_os_campos"));
                return false;
            }

            return true;
        },
        verificaCodigo(code){
            if(this.checkForm()){
                http.get('/purchase/verifyOrderToPay/' + code).then((response) => {
                    this.detalhesPedido = response.data;
                    this.$bvModal.show("detalhes-pedido");
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                });
            }
        },
        pagamento(){
            this.$loading(true);

            http.post('/purchase/payOrder/', this.form).then((response) => {
                this.$snotify.success(this.$i18n.t("pagar_pedido.sucesso"));
                this.form.codigo = '';
                this.closeDetails();
                window.location.reload();
            },
            error => {
                if (error.response && error.response.data && error.response.data.message)
                this.$snotify.error(error.response.data.message);
            }).finally(() => {this.$loading(false)});
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        alterarPagina(pagina) {
            this.formSearch.paginaAtual = pagina;
            this.listarDados();
        },
        filterClick() {
            this.formSearch.paginaAtual = 1;
            this.listarDados();
        },
        // obterDadosUsuario() {
        //     this.$loading(true);
        //     http.get('/Dashboard/getBarStatus/').then((responde) => {
        //         this.dadosUsuario = responde.data;
        //         this.$loading(false);
        //     },error => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         // else
        //         //     this.$snotify.error("An unexpected error has occurred");
        //     });
        // },
        listarDados() {
            this.$loading(true);
            http.post('/extract/fetchExtract', this.formSearch).then((response) => {
                this.listDados = [];
                this.totalPaginas = response.data.totalPages;
                response.data.lancamentos.forEach(item => {
                    this.listDados.push({
                        tipo: item.tipo,
                        data: item.dataLancamento,
                        descricao: item.descricao,
                        valor: item.valor.toFixed(2),
                        valorFormated: '$ ' + item.valor.toFixed(2)
                    })
                });
                this.$loading(false);
            },
            error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
                this.$loading(false);
            });
        },
        obterResumoValores() {
            http.get('/Dashboard/summariesValues/').then((responde) => {
                this.resumoValores = responde.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        
        tipoFiltro() {
            http.get('/general/statement-types').then((responde) => {
                
                this.listTipoFiltro.push({
                    value: null,
                    text: this.$i18n.t("tipos.selecione")
                })
                responde.data.forEach(item => {
                    
                    this.listTipoFiltro.push({
                        value: item.chave,
                        text: this.$i18n.t('tipos.' + item.descricao)
                    });
                });
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        dataMenos15() {
            var result = new Date();
            result.setDate(result.getDate() + -15);
            return result;
        },
        formatDate(data) {
            let dateTime = new Date(data);
            return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        closeDetails(){
            this.$bvModal.hide("detalhes-pedido");
        }
    }
}