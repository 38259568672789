import http from '@/_helper/api-services'
import "./saque.scss";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";
// import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import solicitarSaque from "@/components/SolicitarSaque/solicitarSaque.vue";
import historicoSaque from "@/components/HistoricoSaque/historicoSaque.vue";

export default {
    name: "saque",
    data() {
        return {
            atualizado: true,
            resumoValores: {},
            dadosUsuario: {},
            nomeLogin: '',
            valorMinimo: 0,
            taxaSaque: 0,
            taxaSaqueCash: 0,
            ganhosDias: {},
        };
    },
    components: {
        LinkPatrocinador,
        // PremiosDashboard,
        solicitarSaque,
        historicoSaque,
    },
    beforeMount() {
        this.obterTaxaSaqueUsuario();
        // this.obterDadosUsuario();
    },
    mounted() {
        this.$loading(true);
        //this.obterNomeLogado();
        this.obterResumoValores();
        //this.obterLucroEGanho();
    },
    methods: {
        obterLucroEGanho() {
            //let dia = this.dadosUsuario.diaConsultaSaldo;
            http.post("/Dashboard/balances/" + 15).then((responde) => {
                this.ganhosDias = responde.data;
                this.$loading(false);
            },
            error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        obterTaxaSaqueUsuario() {
            http.get("/withdraw/getRateWithdrawalUser").then(response => {
                this.taxaSaque = response.data.taxa;
                this.taxaSaqueCash = response.data.taxaSaqueCashback;
                this.valorMinimo = response.data.valorMinimo;
            }, error => {
            });
        },
        // obterDadosUsuario() {
        //     this.$loading(true);
        //     http.get('/Dashboard/getBarStatus/').then((responde) => {
        //         this.dadosUsuario = responde.data;
        //         this.$loading(false);
        //     },error => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         // else
        //         //     this.$snotify.error("An unexpected error has occurred");
        //     });
        // },
        obterResumoValores() {
            this.$loading(true);
            http.get('/Dashboard/summariesValues/').then((responde) => {
                this.resumoValores = responde.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                this.$snotify.error(e);
            });
            // else
            //     this.$snotify.error("An unexpected error has occurred");
        });
        this.$loading(false);
        },
        atualizaSaquesSolicitados() {
            this.atualizado = !this.atualizado;
            this.obterResumoValores();
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return this.casasDecimais(valor,2).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        casasDecimais(num, precisao) {
            var casas = Math.pow(10, precisao);
            return Math.floor(num * casas) / casas;
        }
    }
}