import http from '@/_helper/api-services';
import "./TopPage.scss";
// import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";
import CardInvestimento from '@/components/CardInvestimento/CardInvestimento.vue';
import Graduacao from "@/components/Graduacao/Graduacao.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';

export default {
    name: 'TopPage',
    
    data(){
        return{
            isActive: false,
            dadosUsuario: {},
            pontosUsuario: {},
            resumoValores: {},
            dadoLogado: {},
            nomeLogin: '',
        }
    },
    components: {
        // LinkPatrocinador,
        CardInvestimento,
        Graduacao,
        PremiosDashboard
    },
    mounted() {
        // this.obterDadosUsuario();
        this.obterPontosUsuario();
        this.obterResumoValores();
    },
    beforeMount() {
        //this.obterNomeLogado();
    },
    methods: {
        // obterDadosUsuario() {
        //     const loggedIn = localStorage.getItem("user");
        //     if (loggedIn) {
        //         this.$loading(true);
        //         http.get('/Dashboard/getBarStatus/').then((responde) => {
        //             this.dadosUsuario = responde.data;

        //             if (this.dadosUsuario.limiteAtingido == true) {
        //                 this.$bvModal.show('modal-limit');
        //             }
        //         },error => {
        //             if (error.response && error.response.data && error.response.data.errors)
        //                 error.response.data.errors.forEach(e => {
        //                     this.$snotify.error(e);
        //                 });
        //             // else
        //             //     this.$snotify.error("An unexpected error has occurred");
        //         });
        //     }

        //     const forceCountry = localStorage.getItem("force-country");
        //     if (forceCountry) {
        //         this.$bvModal.show('modal-pais');
        //     }
        // },
        obterPontosUsuario() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/user/getUserScore/').then((responde) => {
                    this.pontosUsuario = responde.data;
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
        obterResumoValores() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/Dashboard/summariesValues/').then((responde) => {
                    this.resumoValores = responde.data;
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
    }
}