import http from "@/_helper/api-services.js";
import util from "@/_helper/util.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from 'vue-multiselect'
import "./pagamentos.scss";

export default {
    name: "pagamentos",
    data() {
        return {
            nomeLogin: '',
            availableBalance: 0,
            valorBTCSelecionado: 0,
            pendingReceivedBalance: 0,
            listaPaises: [{ text: "Selecione o país", value: 0 }],
            paisSelecionado: {},
            search: {
                login: String(),
                // email: String(),
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                ativo: true,
                idStatus: 4,
                quantidade: 50,
                page: 1,
                idPais: 0,
                loginPatrocinador: String(),
                moeda: null
            },
            totalRows: 1,
            currentPage: 1,
            resumoSaque: {
                aprovadosSemana: 0,
                aprovadosTotal: 0,
                pendentesSemana: 0,
                valorTaxa: 0,
            },
            listStatus: [],
            tipoSelecionado: 4,
            fields: [
                { key: "selecionado", label: "", sortable: false },
                { key: "login", label: "Login", sortable: true, sortDirection: "desc" },
                { key: "dataSolicitacao", label: "Data Saque", sortable: true },
                { key: "moeda", label: "Moeda" },
                { key: "wallet-key", label: "Wallet-Key", sortable: true },
                {
                    key: "valor",
                    label: "Valor",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `$ ${value.toFixed(2)}`;
                    },
                },
                {
                    key: "taxaSaque",
                    label: "Taxa",
                    sortable: true,
                    formatter: (value) => {
                        return `$ ${value.toFixed(2)}`;
                    },
                },
                {
                    key: "porcentagemSaque",
                    label: "Porcentagem",
                    sortable: true,
                    formatter: (value) => {
                        return `${value.toFixed(2)}%`;
                    },
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    sortable: true
                },
                // {
                //     key: "valorReal",
                //     label: "Valor a receber",
                //     sortable: true,
                //     formatter: (value) => {
                //         if (!value) return "";
                //         return `$ ${value.toFixed(2)}`;
                //     },
                // },
                {
                    key: "status",
                    label: "Status",
                    sortable: true,
                    formatter: (value) => {
                        if (value == "Em processamento") return "Aprovado";
                        return value;
                    },
                },
                {
                    key: "dataAprovacao",
                    label: "Data Aprovação",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return util.formatDate(value);
                    },
                },
                {
                    key: "acoes",
                    label: "Ações",
                }
                // { key: "pagarManual", label: "Ações", sortable: false },
            ],
            fieldsSelecionados: [
                { key: "login", label: "Login", sortable: true, sortDirection: "desc" },
                { key: "dataSolicitacao", label: "Data Saque", sortable: true },
                {
                    key: "valorReal",
                    label: "Valor a receber",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `$ ${value.toFixed(2)}`;
                    },
                },
            ],
            tipoMoeda: [
                { value: null, text: "Tipo moeda" },
                { value: "PIX", text: "PIX" },
                { value: "USDT(TRC20)", text: "USDT(TRC20)" }
            ],
            list: [],
            valorSelecionado: 0,
            selected: [],
            perPage: 50,
            pageOptions: [50, 100, 150, {
                value: 99999,
                text: "show all"
            }],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            action: "",
            filterOn: [],
            todosSelecionados: false,
            usuarioConfiguracao: {
                idUsuarioConfiguracao: 0,
                nomeUsuario: String(),
                idUsuario: String(),
                taxaSaque: Number(),
            },
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",],
            },
            pagamentoManual: {
                idSaque: 0,
                urlTransacao: String(),
            },
            cotacaoBlockChain: Number(),
            twoFactorCode: String()
        };
    },
    components: {
        DatePicker,
        Multiselect
    },

    beforeMount() {
        this.listarPaises();
        this.buscarSaques();
        this.buscarStatusSaque();
        this.buscarResumoSaque();
    },
    mounted() {
        //this.obterNomeLogado();
    },
    watch: {
        perPage() {
            this.search.page = 1;
            this.buscarSaques();
        },
        "search.page"() {
            this.buscarSaques();
        },
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        listarPaises() {
            http.get("/general/getCountries").then(
                success => {
                    if (success.data) {
                        success.data.forEach(item => {
                            this.listaPaises.push({
                                text: item.nome,
                                value: item.idPais
                            });
                        });
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            )

        },
        aprovarSelecionados() {
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum saque selecionado");
            } else {
                this.openModal("aprovar");
            }
        },
        pagarSelecionados() {
            this.$loading(true);
            this.valorSelecionado = 0;
            this.valorBTCSelecionado = 0;
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum saque selecionado");
                this.$loading(false);
            } else {

                http.get("/withdraw/valueInWallet").then(
                    (success) => {
                        var selected = this.list.filter((c) => c.selecionado === true);

                        for (let index = 0; index < selected.length; index++) {
                            var element = selected[index];
                            this.valorSelecionado += element.valorReal;
                        }
                        this.valorBTCSelecionado = (1 / this.cotacaoBlockChain) * this.valorSelecionado;
                        this.availableBalance = success.data.availableBalance;
                        this.pendingReceivedBalance = success.data.pendingReceivedBalance;
                        this.$bvModal.show("modalSaquesSelecionados");
                        this.$loading(false);
                    },
                    (error) => {
                        this.$snotify.error("Sem permissão para consultar saldo");
                        this.$loading(false);
                    }
                );
            }
        },
        cancelarSelecionados() {
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum saque selecionado");
            } else {
                this.openModal("cancelar");
            }
        },
        selecionarTodos() {
            this.buscarSaques();

            //var lista = this.list;
            //for (let index = 0; index < lista.length; index++) {
            //    lista[index].selecionado = this.todosSelecionados;
            //}
            ////for (let index = 0; index < (lista.length / 50); index++) {
            ////    lista = this.selecionarTodosSplit(lista, index * 50, (index + 1) * 50);
            ////}
            //this.list = lista;
        },
        selecionarTodosSplit(lista, de, ate) {
            if (ate > this.list.length)
                ate = this.list.length;

            for (let index = de; index < ate; index++) {
                lista[index].selecionado = this.todosSelecionados;
            }
            return lista;
        },
        selecionados(item) {
            this.selected = this.list.filter((c) => c.selecionado === true);
        },
        totalSelecionado() {
            return this.list.filter((c) => c.selecionado === true).length;
        },
        buscarSaques(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            this.selected = [];
            this.search.quantidade = this.perPage;
            this.tipoSelecionado = this.search.idStatus;

            http.post("/withdraw/fetchWithdrawals", this.search).then(
                (success) => {
                    this.list = [];
                    if (success && success.data) {
                        this.cotacaoBlockChain = success.data.cotacao;
                        success.data.lista.forEach((d) => {
                            this.list.push({
                                selecionado: this.todosSelecionados,
                                dataSolicitacao: d.dataSolicitacao,
                                dataProcessado: d.dataProcessado,
                                idSaque: d.idSaque,
                                status: d.status,
                                login: d.login,
                                nome: d.nome,
                                valor: d.valor,
                                valorReal: d.valorReal,
                                processado: d.processado,
                                enderecoBTC: d.enderecoBTC,
                                enderecoUSDT: d.enderecoUSDT,
                                taxaSaque: d.taxaSaque,
                                tipo: d.tipo,
                                aprovador: d.aprovador,
                                historico: d.historico,
                                dataAprovacao: d.dataAprovacao,
                                idStatus: d.idStatus,
                                urlTransacao: d.urlTransacao,
                                observacao: d.observacao,
                                pais: d.pais,
                                moeda: d.moeda,
                                porcentagemSaque: d.porcentagemSaque
                            });
                        });
                        this.totalRows = this.list.length;
                    }
                    this.selected = [];
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    )
                        this.$snotify.error(error.response.data.message);
                }
            );
        },
        buscarStatusSaque() {
            http.get("/withdraw/getStatusWithdrawal").then((response) => {
                this.listStatus.push({
                    value: 0,
                    text: "Todos",
                });
                response.data.forEach((item) => {
                    this.listStatus.push({
                        value: item.value,
                        text: item.key == "Em processamento" ? "Aprovado" : item.key,
                    });
                });
            });
        },
        buscarResumoSaque() {
            http.get("/withdraw/getSummaryWithdrawal").then((response) => {
                this.resumoSaque.aprovadosSemana = response.data.aprovadosSemana;
                this.resumoSaque.aprovadosTotal = response.data.aprovadosTotal;
                this.resumoSaque.pendentesSemana = response.data.pendentesSemana;
                this.resumoSaque.valorTaxa = response.data.valorTaxa;
            });
        },
        verificarUsuario() {
            http.get("/admin/checkUserAdmin").then((response) => {
                this.openModalPagamentoManual();
            },
                (error) => {
                    this.$snotify.error(error.response.data);
                });
        },
        confirmarAcao() {
            this.$loading(true);
            var url = "/withdraw/cancelWithdrawals";
            if (this.action == "aprovar") url = "/withdraw/approveWithdrawals";
            var selecionados = [];
            this.list
                .filter((c) => c.selecionado === true)
                .forEach((l) => {
                    selecionados.push({
                        idSaque: l.idSaque,
                    });
                });

            http.post(url, selecionados).then(
                (success) => {
                    if (this.action === "aprovar") {
                        this.$snotify.success("Saques aprovados!");
                    } else {
                        this.$snotify.success("Saques cancelados!");
                    }
                    this.buscarSaques();
                    this.buscarResumoSaque();
                    this.fecharModal();
                    this.selected = [];
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                    this.fecharModal();
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    )
                        this.$snotify.error(error.response.data.message);
                }
            );
        },
        fecharModal() {
            this.$bvModal.hide("modal-confirm");
        },
        openModal(tipo) {
            this.action = tipo;
            this.$bvModal.show("modal-confirm");
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        formatDate(data) {
            return util.formatDate(data);
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        handleSubmit(event) {
            event.preventDefault();
            this.$loading(true);
            this.pagamentoManual.idSaque = +this.pagamentoManual.idSaque;
            http
                .post("/withdraw/paymentManual", this.pagamentoManual)
                .then(
                    (success) => {
                        if (success.data && success.data.message) {
                            this.$snotify.success(success.data.message);
                            this.closeModalPagamentoManual();
                            this.buscarSaques();
                        }
                    },
                    (error) => {
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors
                        )
                            error.response.data.errors.forEach((message) =>
                                this.$snotify.error(message)
                            );
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        open(Id) {
            alert(1);
        },
        processarNovamente(event) {
            event.preventDefault();
            this.$loading(true);
            this.pagamentoManual.idSaque = +this.pagamentoManual.idSaque;
            http
                .post("/withdraw/processAgain", this.pagamentoManual)
                .then(
                    (success) => {
                        if (success.data && success.data.message) {
                            this.$snotify.success(success.data.message);
                            this.closeModalPagarNovamente();
                            this.buscarSaques();
                        }
                    },
                    (error) => {
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors
                        )
                            error.response.data.errors.forEach((message) =>
                                this.$snotify.error(message)
                            );
                    }
                )
                .finally(() => {
                    this.closeModalPagarNovamente();
                    this.$loading(false);
                });
        },
        openModalPagamentoManual(idSaque) {
            this.$root.$emit("bv::hide::popover");
            this.pagamentoManual.idSaque = +idSaque;
            this.$bvModal.show("modalPagarManual");
        },
        closeModalPagamentoManual() {
            this.$bvModal.hide("modalPagarManual");
            this.pagamentoManual.idSaque = 0;
        },
        closeModalPagarNovamente() {
            this.$bvModal.hide("modalPagarNovamente");
            this.pagamentoManual.idSaque = 0;
        },
        openModalPagamentoManual() {
            this.$bvModal.show("modalSaquesAprovado");
        },
        openPagamentoManual(idSaque) {
            this.pagamentoManual.idSaque = +idSaque;
            this.$bvModal.show("modalPagarManual");
        },
        openProcessarNovamente(idSaque) {
            this.pagamentoManual.idSaque = +idSaque;
            this.$bvModal.show("modalPagarNovamente");
        },
        async pagarSaquesAprovados(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            this.$bvModal.hide("modalSaquesAprovado");
            await http.post("/withdraw/payApprovedWithdrawals", { cotacao: +this.cotacaoBlockChain, codigo: this.twoFactorCode })
                .then(
                    (success) => {
                        if ((success.status = 200)) {
                            this.$snotify.success("Pagamentos efetuados com sucesso");
                            // this.$bvModal.hide("modalSaquesAprovado");
                            this.buscarSaques();
                        } else {
                            this.$snotify.error("Erro ao efetuar pagamentos");
                        }
                    },
                    (error) => {
                        this.$snotify.error(error.response.data);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },

        async pagarSaquesSelecionados(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            var selecionados = [];
            this.list
                .filter((c) => c.selecionado === true)
                .forEach((l) => {
                    selecionados.push({
                        idSaque: l.idSaque,
                    });
                });

            if (selecionados.length == 0) {
                this.$snotify.error("Nenhum saque selecionado.");
                this.$loading(false);
                return false;
            }
            this.$bvModal.hide("modalSaquesSelecionados");

            await http.post("/withdraw/paySelectedWithdrawals", { lista: selecionados, cotacao: +this.cotacaoBlockChain, codigo: this.twoFactorCode })
                .then(
                    (success) => {
                        if ((success.status = 200)) {
                            this.$snotify.success("Pagamentos enviados para a fila de processamento e logo estarão finalizados.");
                            // this.$bvModal.hide("modalSaquesSelecionados");
                            this.buscarSaques();
                        } else {
                            this.$snotify.error("Erro ao efetuar pagamentos");
                        }
                        this.$loading(false);
                    },
                    (error) => {
                        this.$snotify.error(error.response.data);
                        this.$loading(false);
                    }
                );
        },
    },
};