import http from '@/_helper/api-services';
import './DadosCompra.scss';
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


export default {
    name: 'DadosCompra',
    data() {
        return {
            paymentForms: [],
            paymentFormsTraducao: [],
            locale: 'en',
            search: '',
            dadosUsuario: {},
            blockButton: false,
            fields: [
                { key: 'produto', label: this.$t('minhas_compras.produto'), sortable: false, class: 'text-left' },
                { key: 'codigoPedido', label: this.$t('minhas_compras.codigo'), sortable: true, class: 'text-center' },
                { key: 'data', label: this.$t('minhas_compras.data'), sortable: true, class: 'text-center' },
                { key: 'valor', label: this.$i18n.t("minhas_compras.valor"), sortable: true, class: 'text-center' },
                {
                    key: 'meioPagamento',
                    label: this.$i18n.t("minhas_compras.tipo_pagamento"),
                    sortable: true,
                    class: 'text-center',
                    formatter: value => {
                        return this.paymentFormsTraducao.find(p => p.tipo === value) ? this.$t("minhas_compras." + this.paymentFormsTraducao.find(p => p.tipo === value).nome) : "";
                    }
                },
                { key: 'status', label: this.$t('minhas_compras.status'), sortable: true, class: 'text-center' },
                { key: 'dataPagamento', label: this.$t('minhas_compras.data_pagamento'), sortable: true, class: 'text-center' },
                { key: 'visualizar', label: this.$i18n.t("minhas_compras.visualizar"), sortable: true, class: 'text-center' },
            ],
            listDados: [],
            listTipoFiltro: [{ value: 0, text: this.$i18n.t("status.todos") }],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            pedidoRemover: {
                idPedido: 0,
                codigoPedido: ''
            },
            filterOn: [],
            filtroBusca: {
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                idStatus: 0
            },
            lang: {},
            pedidoSelecionado: {
                produto: String(),
                data: String(),
                valor: String(),
                cotacao: Number(),
                valorTaxa: Number(),
                status: String(),
                codigoPedido: String(),
                valorBTC: Number(),
                moeda: String(),
                upgrade : false,
                renovacao : false,
                endereco:{
                    rua: String(),
                    numero: String(),
                    cep: String(),
                    complemento: String(),
                    cidade: String(),
                    estado: String(),
                },
                visualizar: {
                    status: String(),
                    codigo: String(),
                    enderecoDeposito: String(),
                    urlPagamento: String()
                }
            }
        }
    },
    watch: {

    },
    components: {
        VueSlickCarousel,
        DatePicker
    },
    // async beforeMount() {
    //     this.$loading(true);
    //     await this.getPaymentsFormsForTranslation();
    //     await this.getPaymentsForms();
    //     this.$loading(false);
    // },
    beforeMount() {
        // this.obterDadosUsuario();
        this.getPaymentsFormsForTranslation();
        this.getPaymentsForms();
    },
    mounted() {
        this.verificaParamUrl();
        this.buscarFiltroTipo();
        this.buscarPedidos();
    },
    methods: {
        // obterDadosUsuario() {
        //     this.$loading(true);
        //     http.get('/Dashboard/getBarStatus/').then((responde) => {
        //         this.dadosUsuario = responde.data;
        //         this.$loading(false);
        //     },error => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         // else
        //         //     this.$snotify.error("An unexpected error has occurred");
        //     });
        // },
        formatValor(valor) {
            if(!valor)
                return '$0,00';

            return valor.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        async getPaymentsForms() {
            await http.get("/purchase/getPaymentMethods").then(success => {
                this.paymentForms = success.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        async getPaymentsFormsForTranslation() {
            await http.get("/purchase/getPaymentMethodsTranslation").then(success => {
                this.paymentFormsTraducao = success.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        buscarFiltroTipo() {
            http.get('/general/status').then((response) => {
                response.data.forEach(item => {
                    if (item.nome != 'Processando pagamento') {
                        this.listTipoFiltro.push({
                            value: item.idStatus,
                            text: this.$i18n.t("status." + item.nome)
                        });
                    }
                });

                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        buscarPedidos() {
            this.$loading(true);
            this.listDados = [];
            http.post('/requests/listOrdersProduct', this.filtroBusca).then((response) => {
                response.data.lstPedidos.forEach(item => {
                    this.listDados.push({
                        produto: item.nomeProduto,
                        idPedido: item.idPedido,
                        codigoPedido: item.codigoPedido,
                        data: item.dataPedido,
                        dataPagamento: item.dataPagamento,
                        valor: item.valorPedido,
                        valorTaxa: item.valorTaxa,
                        cotacao: item.cotacao,
                        status: item.statusTransacao,
                        pago: item.pago,
                        meioPagamento: item.meioPagamento,
                        informacaoAdicional: item.informacaoAdicional,
                        upgrade : item.upgrade,
                        renovacao : item.renovacao,
                        // boleto: item.urlBoleto
                        valorBTC: (((item.valorPedido + item.valorTaxa) * 1.002) / item.cotacao.toFixed(2)).toFixed(8),
                        visualizar: { status: item.statusTransacao, codigo: item.codigoPedido, enderecoDeposito: item.enderecoDeposito, urlPagamento: item.urlPagamento, idPedido: item.idPedido },
                    });
                });
            }, error => {
                if (error.response && typeof error.response.data == "object")
                    this.$snotify.error(error.response.data.message);
            }).finally(() => { this.$loading(false); });
        },
        modalCancelarPedido(pedido) {
            this.pedidoRemover.idPedido = pedido.idPedido;
            this.pedidoRemover.codigoPedido = pedido.codigo;
            this.$bvModal.show('modal-cancelar-pedido');
        },
        closeDetails() {
            this.$bvModal.hide('modal-pedido');
        },
        closeCancel() {
            this.$bvModal.hide('modal-cancelar-pedido');
        },
        cancelarPedido() {
            if (this.blockButton == true) {
                return false;
            }

            this.blockButton = true;
            this.$loading(true);
            http.delete(`/requests/cancelOrder/${this.pedidoRemover.idPedido}`).then(
                success => {
                    this.pedidoRemover.idPedido = 0;
                    this.$snotify.success("Order canceled.");
                    this.modalConfirmDelete = false;
                    this.buscarPedidos();
                    this.$bvModal.hide('modal-cancelar-pedido');
                    this.blockButton = false;
                    this.$loading(false);
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");

                    this.blockButton = false;
                    this.$loading(false);
                }
            );
            
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            }
        },
        openModal(codigo) {
            this.$loading(true);
            let object = this.listDados.filter(f => f.codigoPedido == codigo);
            if (object) {
                var tax = 1.002;
                if (object[0].meioPagamento == 12) {
                    tax = 1;
                }
                this.pedidoSelecionado.produto = object[0].produto;
                this.pedidoSelecionado.upgrade = object[0].upgrade;
                this.pedidoSelecionado.renovacao = object[0].renovacao;
                this.pedidoSelecionado.data = object[0].data;
                this.pedidoSelecionado.valor = object[0].valor;
                this.pedidoSelecionado.moeda = this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento) ? this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento).nome : "";
                this.pedidoSelecionado.valorTaxa = object[0].valorTaxa;
                this.pedidoSelecionado.cotacao = object[0].cotacao;
                this.pedidoSelecionado.status = object[0].status;
                this.pedidoSelecionado.informacaoAdicional = object[0].informacaoAdicional;
                this.pedidoSelecionado.codigoPedido = object[0].codidoPedido;
                this.pedidoSelecionado.visualizar.status = object[0].visualizar.statusTransacao;
                this.pedidoSelecionado.visualizar.codigo = object[0].visualizar.codidoPedido;
                this.pedidoSelecionado.visualizar.idPedido = object[0].visualizar.idPedido;
                this.pedidoSelecionado.visualizar.urlPagamento = object[0].visualizar.urlPagamento;
                this.pedidoSelecionado.visualizar.enderecoDeposito = object[0].visualizar.enderecoDeposito;
                this.pedidoSelecionado.valorBTC = ((this.pedidoSelecionado.valor + this.pedidoSelecionado.valorTaxa * tax) / this.pedidoSelecionado.cotacao).toFixed(8);
                if (object[0].meioPagamento == 12) {
                    this.pedidoSelecionado.valorBTC = ((this.pedidoSelecionado.valor + this.pedidoSelecionado.valorTaxa * tax) / this.pedidoSelecionado.cotacao).toFixed(0);
                }
            }

            this.$bvModal.show('modal-pedido');

            
            setInterval(() => {
                this.$loading(false);
            }, 500);
        },
        verificaParamUrl() {
            if (this.$route.params.codigoPedido) {
                http.get('/requests/searchProductPhysicalByCode/' + this.$route.params.codigoPedido).then((response) => {
                   
                    if (response.data) {
                        if (!response.data.urlPagamento || response.data.moeda == 'Saldo') {
                            var tax = 1.002;
                            if (response.data.moeda == 'TRON_USDT') {
                                tax = 1;
                            }
                            this.pedidoSelecionado.produto = response.data.nomeProduto;
                            this.pedidoSelecionado.data = response.data.dataPedido;
                            this.pedidoSelecionado.upgrade = response.data.upgrade;
                            this.pedidoSelecionado.renovacao = response.data.renovacao;
                            this.pedidoSelecionado.valor = response.data.valorPedido;
                            this.pedidoSelecionado.moeda = response.data.moeda;
                            this.pedidoSelecionado.valorTaxa = response.data.valorTaxa;
                            this.pedidoSelecionado.cotacao = response.data.cotacao;
                            this.pedidoSelecionado.status = response.data.statusTransacao;
                            this.pedidoSelecionado.codigoPedido = response.data.codidoPedido;
                            this.pedidoSelecionado.visualizar.status = response.data.statusTransacao;
                            this.pedidoSelecionado.visualizar.codigo = response.data.codidoPedido;
                            this.pedidoSelecionado.visualizar.informacaoAdicional = response.data.informacaoAdicional;
                            this.pedidoSelecionado.visualizar.urlPagamento = response.data.urlPagamento;
                            this.pedidoSelecionado.visualizar.idPedido = response.data.idPedido;
                            this.pedidoSelecionado.visualizar.enderecoDeposito = response.data.enderecoDeposito;
                            this.pedidoSelecionado.endereco.rua = response.data.rua;
                            this.pedidoSelecionado.endereco.numero = response.data.numero;
                            this.pedidoSelecionado.endereco.cep = response.data.cep;
                            this.pedidoSelecionado.endereco.complemento = response.data.complemento;
                            this.pedidoSelecionado.endereco.cidade = response.data.cidade;
                            this.pedidoSelecionado.endereco.estado = response.data.estado;
                            this.pedidoSelecionado.valorBTC = (((response.data.valorPedido + response.data.valorTaxa) * tax) / response.data.cotacao.toFixed(2)).toFixed(8);
                            if (response.data.moeda == 'TRON_USDT') {
                                this.pedidoSelecionado.valorBTC = (((response.data.valorPedido + response.data.valorTaxa) * tax) / response.data.cotacao.toFixed(2)).toFixed(0);
                            }
                            this.$bvModal.show('modal-pedido');
                        } else
                            window.open(response.data.urlPagamento, '_blank');
                    }
                    this.$loading(false);
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
        verificaFormaPagamento(object) {
            if (!object.urlPagamento || response.data.moeda == 'Saldo')
                this.openModal(object.codigo);
            else
                window.open(object.urlPagamento, '_blank');
        }
    }
}