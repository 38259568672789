import http from '@/_helper/api-services'
import './GestaoAnuncio.scss'

export default {
    name: 'GestaoAnuncio',
    data() {
        return {
            campanhas: [],
            infoCampanha: {}
        }
    },
    components: {
    },
    beforeMount(){
        this.getCampains();
    },
    methods:{
        getCampains() {
            http.get("/Campanha/getCampains").then((response) => {
                this.campanhas = response.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
            });
        },
        getCampains() {
            http.get("/Campanha/infoCampains").then((response) => {
                this.campanhas = response.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
            });
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return this.casasDecimais(valor,2).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        casasDecimais(num, precisao) {
            var casas = Math.pow(10, precisao);
            return Math.floor(num * casas) / casas;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString();
            } else {
                return "";
            }
        },
        formatPercentage(value) {
            if(!value)
                return '0.00%';

            return value.toFixed(2) + '%';
        },
        formatValue(value){
            if(!value)
                return '0';

            return value;
        }
    }
}