import "./resetPassword.scss";
import formAlterarSenha from "@/components/FormAlterarSenha/formAlterarSenha.vue";
import ListaIdioma from '@/components/ListaIdioma/ListaIdioma.vue';
// import appFooter from "@/components/AppFooter/appFooter.vue";

export default {
    name: "resetPassword",
    data() {
        return {
            year: new Date(Date.now()).getFullYear(),
            token: String(),
            searchMenu: Boolean()
        };
    },
    components: {
        ListaIdioma,
        formAlterarSenha,
        // appFooter
    },
    beforeMount() {
        this.token = this.$route.params.token;
    },
    mounted() {
        this.$loading(false);
    }
}