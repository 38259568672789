import http from '@/_helper/api-services';
import './MinhasCompras.scss';
// import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
// import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import VueElementLoading from "vue-element-loading";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";

export default {
    name: 'MinhasCompras',
    data() {
        return {
            message: 'Copy These Text',
            paymentForms: [],
            paymentFormsTraducao: [],
            locale: 'en',
            search: '',
            dadosUsuario: {},
            resumoValores: {},
            nomeLogin: '',
            blockButton: false,
            fields: [
                { key: 'produto', label: this.$t('minhas_compras.produto'), sortable: false, class: 'text-left' },
                { key: 'codigoPedido', label: this.$t('minhas_compras.codigo'), sortable: true, class: 'text-center' },
                { key: 'data', label: this.$t('minhas_compras.data'), sortable: true, class: 'text-center' },
                { key: 'valor', label: this.$i18n.t("minhas_compras.valor"), sortable: true, class: 'text-center' },
                {
                    key: 'meioPagamento',
                    label: this.$i18n.t("minhas_compras.tipo_pagamento"),
                    sortable: true,
                    class: 'text-center',
                    formatter: value => {
                        return this.paymentFormsTraducao.find(p => p.tipo === value) ? this.$t("minhas_compras." + this.paymentFormsTraducao.find(p => p.tipo === value).nome) : "";
                    }
                },
                { key: 'status', label: this.$t('minhas_compras.status'), sortable: true, class: 'text-center' },
                { key: 'dataPagamento', label: this.$t('minhas_compras.data_pagamento'), sortable: true, class: 'text-center' },
                { key: 'visualizar', label: this.$i18n.t("minhas_compras.visualizar"), sortable: true, class: 'text-center' },
            ],
            listDados: [],
            listTipoFiltro: [{ value: 0, text: this.$i18n.t("status.todos") }],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            pedidoRemover: {
                idPedido: 0,
                codigoPedido: ''
            },
            filterOn: [],
            filtroBusca: {
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                idStatus: 0
            },
            lang: {},
            pedidoSelecionado: {
                produto: String(),
                data: String(),
                valor: String(),
                cotacao: Number(),
                valorTaxa: Number(),
                status: String(),
                codigoPedido: String(),
                valorBTC: Number(),
                moeda: String(),
                upgrade: false,
                renovacao: false,
                taxaRepasse: String(),
                visualizar: {
                    status: String(),
                    codigo: String(),
                    enderecoDeposito: String(),
                    urlPagamento: String(),
                    // taxaRepasse: String(),
                }
            },
            taxaRepasse: String(),
            comprovante: {
                arquivo: null,
                idPedido: Number()
            },
            arquivo: null,
            arquivoVisualizar: "",
            show: true,
            counter: 0,
            timerPayment: 0,
            pedidoPago: false,
            pedido:{},
            timer:{
                hora: 0,
                minuto: 0,
                segundo: 0
            }
        }
    },
    watch: {
        "arquivo"(e) {
            let reader = new FileReader();
            reader.readAsDataURL(e);
            reader.onload = () => {
                this.comprovante.arquivo = reader.result;
            };
        },
    },
    components: {
        LinkPatrocinador,
        VueElementLoading
        // PremiosDashboard,
        // DatePicker,
    },
    // async beforeMount() {
    //     this.$loading(true);
    //     await this.getPaymentsFormsForTranslation();
    //     await this.getPaymentsForms();
    //     this.$loading(false);
    // },
    beforeMount() {
        // this.obterDadosUsuario();
        this.obterResumoValores();
        this.getPaymentsFormsForTranslation();
        this.getPaymentsForms();
    },
    mounted() {
        //this.contador();
        //this.obterNomeLogado();
        this.verificaParamUrl();
        this.buscarFiltroTipo();
        this.buscarPedidos();
    },
    methods: {
        async subirComprovante() {
            this.$loading(true);
            this.comprovante.idPedido = this.pedidoSelecionado.visualizar.idPedido;
           
            if (this.comprovante && this.comprovante.arquivo) {
                await http.post('/requests/uploadPaymentVoucher/', this.comprovante).then((responde) => {
                    this.buscarPedidos();
                    this.$bvModal.hide('modal-pedido');
                    this.$snotify.success(this.$i18n.t("generico.enviado_sucesso"));

                }, error => {
                    
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                }).finally(() => {
                    this.$loading(false);
                });
            } else {
                this.$loading(false);
                this.$snotify.error(this.$i18n.t("erros.anexar_comprovante"));
            }
        },
        copyURL() {
            var Url = document.getElementById('enderecoDeposito').innerText;
            navigator.clipboard.writeText(Url);
            this.onCopy(Url);
        },
        onCopy(e) {
            this.$snotify.success(this.$i18n.t("generico.copiar_link"));
        },
        onError(e) {
            //this.$snotify.error(this.$i18n.t("erros.erro_desconhecido"));
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        obterResumoValores() {
            this.$loading(true);
            http.get('/Dashboard/summariesValues/').then((responde) => {
                this.resumoValores = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
            });
        },
        // obterDadosUsuario() {
        //     this.$loading(true);
        //     http.get('/Dashboard/getBarStatus/').then((responde) => {
        //         this.dadosUsuario = responde.data;
        //         this.$loading(false);
        //     },error => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         // else
        //         //     this.$snotify.error("An unexpected error has occurred");
        //     });
        // },
        formatValor(valor) {
            if(!valor)
                return '$0,00';

            return this.casasDecimais(valor,2).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        formatValorReal(valor) {
            if(!valor)
                return 'R$0.00';

            return this.casasDecimais(valor,2).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        },
        casasDecimais(num, precisao) {
            var casas = Math.pow(10, precisao);
            return Math.floor(num * casas) / casas;
        },
        async getPaymentsForms() {
            await http.get("/purchase/getPaymentMethods").then(success => {
                this.paymentForms = success.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        async getPaymentsFormsForTranslation() {
            await http.get("/purchase/getPaymentMethodsTranslation").then(success => {
                this.paymentFormsTraducao = success.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        buscarFiltroTipo() {
            http.get('/general/status').then((response) => {
                response.data.forEach(item => {
                    if (item.nome != 'Processando pagamento') {
                        this.listTipoFiltro.push({
                            value: item.idStatus,
                            text: this.$i18n.t("status." + item.nome)
                        });
                    }
                });

                this.$loading(false);
            });
        },
        buscarPedidos() {
            this.$loading(true);
            
            http.post('/requests/orderList', this.filtroBusca).then((response) => {
                this.listDados = [];
                this.taxaRepasse = response.data.taxaRepasse;
                response.data.lstPedidos.forEach(item => {
                    this.listDados.push({
                        produto: item.nomeProduto,
                        idPedido: item.idPedido,
                        codigoPedido: item.codigoPedido,
                        data: item.dataPedido,
                        dataPagamento: item.dataPagamento,
                        valor: item.valorPedido,
                        valorTaxa: item.valorTaxa,
                        cotacao: item.cotacao,
                        status: item.statusTransacao,
                        pago: item.pago,
                        meioPagamento: item.meioPagamento,
                        informacaoAdicional: item.informacaoAdicional,
                        upgrade: item.upgrade,
                        renovacao: item.renovacao,
                        // boleto: item.urlBoleto
                        valorBTC: (((item.valorPedido - item.valorTaxa) * 1.000) / item.cotacao.toFixed(2)).toFixed(8),
                        visualizar: { status: item.statusTransacao, codigo: item.codigoPedido, enderecoDeposito: item.enderecoDeposito, urlPagamento: item.urlPagamento, idPedido: item.idPedido },
                    });
                });
                this.totalRows=this.listDados.length;
                

            }, error => {
                if (error.response && typeof error.response.data == "object")
                    this.$snotify.error(error.response.data.message);
            }).finally(() => { this.$loading(false); });
        },
        modalCancelarPedido(pedido) {
            this.pedidoRemover.idPedido = pedido.idPedido;
            this.pedidoRemover.codigoPedido = pedido.codigo;
            this.$bvModal.show('modal-cancelar-pedido');
        },
        closeDetails() {
            this.$bvModal.hide('modal-pedido');
            clearInterval( this.counter );
        },
        closeCancel() {
            this.$bvModal.hide('modal-cancelar-pedido');
        },
        cancelarPedido() {
            if (this.blockButton == true) {
                return false;
            }

            this.blockButton = true;
            this.$loading(true);
            http.delete(`/requests/cancelOrder/${this.pedidoRemover.idPedido}`).then(
                success => {
                    this.pedidoRemover.idPedido = 0;
                    this.$snotify.success("Order canceled.");
                    this.modalConfirmDelete = false;
                    this.buscarPedidos();
                    this.$bvModal.hide('modal-cancelar-pedido');
                    this.blockButton = false;
                    this.$loading(false);
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");

                    this.blockButton = false;
                    this.$loading(false);
                }
            );

        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            }
        },
        openModal(codigo) {
            // this.$loading(true);
            let object = this.listDados.filter(f => f.codigoPedido == codigo);
            if (object) {
                var tax = 1.000;
                if (object[0].meioPagamento == 12) {
                    tax = 1;
                }
                this.pedidoSelecionado.produto = object[0].produto;
                this.pedidoSelecionado.upgrade = object[0].upgrade;
                this.pedidoSelecionado.renovacao = object[0].renovacao;
                this.pedidoSelecionado.data = object[0].data;
                this.pedidoSelecionado.valor = object[0].valor;
                this.pedidoSelecionado.moeda = this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento) ? this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento).nome : "";
                this.pedidoSelecionado.valorTaxa = object[0].valorTaxa;
                this.pedidoSelecionado.cotacao = object[0].cotacao;
                this.pedidoSelecionado.status = object[0].status;
                this.pedidoSelecionado.informacaoAdicional = object[0].informacaoAdicional;
                //this.pedidoSelecionado.codigoPedido = object[0].codidoPedido;
                this.pedidoSelecionado.codigoPedido = codigo;
                this.pedidoSelecionado.taxaRepasse = this.taxaRepasse;
                this.pedidoSelecionado.visualizar.status = object[0].visualizar.statusTransacao;
                this.pedidoSelecionado.visualizar.codigo = object[0].visualizar.codidoPedido;
                this.pedidoSelecionado.visualizar.idPedido = object[0].visualizar.idPedido;
                this.pedidoSelecionado.visualizar.urlPagamento = object[0].visualizar.urlPagamento;
                this.pedidoSelecionado.visualizar.enderecoDeposito = object[0].visualizar.enderecoDeposito;
                this.pedidoSelecionado.valorBTC = this.formatValor((this.pedidoSelecionado.valor + this.pedidoSelecionado.valorTaxa * tax));
                if (object[0].meioPagamento == 13) {
                    this.pedidoSelecionado.valorBTC = this.formatValorReal((this.pedidoSelecionado.valor + this.pedidoSelecionado.valorTaxa * tax) * this.pedidoSelecionado.cotacao);
                }

                
            }
            //this.$bvModal.show('modal-pedido');
            if(this.pedidoSelecionado.status == 'Em processamento'){
                this.atualizaPagamento(this.pedidoSelecionado.codigoPedido);
            }
            this.abrirModalPedido();
        },
        verificaParamUrl() {
            if (this.$route.params.codigoPedido) {
                http.get('/requests/fetchProductByCode/' + this.$route.params.codigoPedido).then((response) => {
                    if (response.data) {
                        // if (!response.data.urlPagamento || response.data.moeda == 'Saldo') {
                            var tax = 1.000;
                            if (response.data.moeda == 'TRON_USDT') {
                                tax = 1;
                            }
                            this.pedidoSelecionado.produto = response.data.nomeProduto;
                            this.pedidoSelecionado.data = response.data.dataPedido;
                            this.pedidoSelecionado.upgrade = response.data.upgrade;
                            this.pedidoSelecionado.renovacao = response.data.renovacao;
                            this.pedidoSelecionado.valor = response.data.valorPedido;
                            this.pedidoSelecionado.moeda = response.data.moeda;
                            this.pedidoSelecionado.valorTaxa = response.data.valorTaxa;
                            this.pedidoSelecionado.cotacao = response.data.cotacao;
                            this.pedidoSelecionado.status = response.data.statusTransacao;
                            this.pedidoSelecionado.codigoPedido = response.data.codidoPedido;
                            this.pedidoSelecionado.taxaRepasse = this.taxaRepasse;
                            this.pedidoSelecionado.visualizar.status = response.data.statusTransacao;
                            this.pedidoSelecionado.visualizar.codigo = response.data.codidoPedido;
                            this.pedidoSelecionado.visualizar.informacaoAdicional = response.data.informacaoAdicional;
                            this.pedidoSelecionado.visualizar.urlPagamento = response.data.urlPagamento;
                            this.pedidoSelecionado.visualizar.idPedido = response.data.idPedido;
                            this.pedidoSelecionado.visualizar.enderecoDeposito = response.data.enderecoDeposito;
                            this.pedidoSelecionado.valorBTC = this.formatValor(((response.data.valorPedido + response.data.valorTaxa) * tax));
                            if ( response.data.moeda == 'Pix') {
                                this.pedidoSelecionado.valorBTC = this.formatValorReal(((response.data.valorPedido + response.data.valorTaxa) * tax) * this.pedidoSelecionado.cotacao);
                            }
                            //this.$bvModal.show('modal-pedido');
                            if(this.pedidoSelecionado.status == 'Em processamento'){
                                this.atualizaPagamento(this.pedidoSelecionado.codigoPedido);
                            }

                            this.abrirModalPedido();
                        // } else
                        //     window.open(response.data.urlPagamento, '_blank');

                    }

                    this.$loading(false);
                });
            }

        },
        verificaFormaPagamento(object, moeda) {
            if (!object.urlPagamento)
                this.openModal(object.codigo);
            else if(object.urlPagamento && moeda == 7)
                this.openModal(object.codigo);
            else if(object.urlPagamento && moeda == 13)
                this.openModal(object.codigo);
            else
                window.open(object.urlPagamento, '_blank');
        },
        abrirComprovante(url) {
            this.arquivoVisualizar = url;
            this.$bvModal.show('modal-comprovante');
        },
        fecharComprovante() {
            this.$bvModal.hide('modal-comprovante');
        },
        abrirModalPedido(){
            this.$bvModal.show('modal-pedido');
        },
        atualizaPagamento(codigo){
            if(this.pedidoPago == false){
                this.counter = setInterval(() => {
                   http.post("/requests/verifyOrder/" + codigo).then((response) => {
                    this.pedido = response.data;
                   });
                   if(this.pedido.pago == true){
                       this.pedidoSelecionado.status = this.pedido.statusTransacao;
                       this.pedidoPago = true;
                       this.$snotify.success(this.$i18n.t("generico.enviado_sucesso"));
                       this.atualizaPagamento(this.pedidoSelecionado.codigoPedido);
                       this.buscarPedidos();
                   }
               }, 4000);
            }
            else{
                clearInterval( this.counter );
            }
        },
        CountTimer(){
            http.get("/Dashboard/timerBinary/").then((response) => {
                const difference = response.data.totalMilliseconds;
                const hours = Math.floor(difference / 1000 / 60 / 60) % 24;
                const minutes = Math.floor(difference / 1000 / 60) % 60;
                const seconds = Math.floor(difference / 1000) % 60;

                this.timer.hora = hours < 10 ? '0' + hours : hours;
                this.timer.minuto = minutes < 10 ? '0' + minutes : minutes;
                this.timer.segundo = seconds < 10 ? '0' + seconds : seconds;
            })
        },
        contador(){
            this.timerPayment = window.setInterval(this.CountTimer, 1000);

        },
    },
    beforeDestroy(){
        clearInterval( this.timerPayment );
      }
}