import http from '@/_helper/api-services'
import './Arquivos.scss'


export default {
    name: 'Arquivos',
    data() {
        return {
        
        }
    },
    components: {

    }
}