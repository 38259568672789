import DatePicker from 'vue2-datepicker';
import api from "@/_helper/api-services.js";

export default {
    name: "gerenciarPedidos",
    data() {
        return {
            nomeLogin: '',
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            formSearch: {
                dataInicial: this.dataMenos30(),
                dataFinal: new Date(),
                quantidadePorPagina: 25,
                pagina: 1,
                login: '',
                codigo: '',
                hash: '',
                Pago: null,
                idStatus: 2,
                idCategoria: null,
                meioPagamento: null

            },
            totalPaginas: 0,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            fields: [
                { key: "codigo", label: "Código" },
                { key: "login", label: "Login", class: "text-center" },
                { key: "pago", label: "Pago?", class: "text-center" },
                { key: "moeda", label: "Meio de Pagamento", class: "text-center", formatter: val => { if (!val) return ""; return val.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) } },
                { key: "valorPedido", label: "Valor USD", class: "text-center", formatter: val => { if (!val) return ""; return val.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) } },
                { key: "dataPedido", label: "Data", class: "text-center", formatter: val => { if (!val) return ""; return `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}` } },
                { key: "acoes", label: "" }
            ],
            items: [],
            showIconsToChangePage: false,
            idPedido: null,
            modalConfirm: false,
            modalConfirmDelete: false,
            modalEdit: false,
            opcoesStatus: [
                { text: 'Todos', value: 0 },
                { text: 'Em processamento', value: 1 },
                { text: 'Finalizado', value: 2 },
                { text: 'Cancelado', value: 3 }
            ],
            opcoesCategoria: [
                { text: 'Todos', value: null },
                { text: 'Plano', value: 2 },
                { text: 'Físico', value: 8 },
            ],
            opcoesPagamento: [
                { text: 'Todos', value: null },
                { text: 'Pago', value: true },
                { text: 'Não Pago', value: false },
            ],
            opcoesMeioPagamento: [
                {text: 'Todos', value: null},
                {text: 'BTC', value: 1},
                {text: 'USDT', value: 12},
                {text: 'PIX', value: 13}
            ],
            listTipos: [],
            tipoAtivacao: 0,
            arquivoVisualizar: ""
        };
    },
    watch: {
    },
    computed: {
    },
    components: {
        DatePicker,
    },
    async mounted() {
        await this.getPedidos();
        this.carregarTiposPagamento();
        //this.obterNomeLogado();
    },
    // mounted() {
    // },
    methods: {
        editarPedido(idPedido) {
            this.IdPedido = idPedido;
            this.modalEdit = true
        },
        async confirmEdit() {
            let data = {
                idPedido: this.IdPedido,
                meioPagamento: this.tipoAtivacao
            };
            await api.post("/admin/editRequestPayment", data).then((response) => {
                this.$snotify.success(response.data.message);
                this.idPedido = null;
                this.tipoAtivacao = 0;
                this.getPedidos();
            },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                }).finally(() => {
                    this.modalEdit = false;
                })
        },
        carregarTiposPagamento() {
            this.listTipos.push({ value: 3, text: this.$i18n.t("admin.ativar_contas.txt_ativacao_manual") })
            this.listTipos.push({ value: 4, text: this.$i18n.t("admin.ativar_contas.txt_voucher_01") })
            this.listTipos.push({ value: 5, text: this.$i18n.t("admin.ativar_contas.txt_voucher_02") })
            this.listTipos.push({ value: 11, text: this.$i18n.t("admin.ativar_contas.txt_voucher_03") })
            this.$loading(false);
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        async searchClick() {
            this.formSearch.pagina = 1;
            await this.getPedidos();
        },
        async getPedidos() {
            this.$loading(true);
            await api.post("/Admin/listPendingOrders", this.formSearch).then(
                success => {
                    this.items = success.data.pedidosFiltrados;
                    this.totalRows = this.items.length;
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                }
            )
            this.$loading(false);
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        formatBTC(val) {
            if (!val) return "0.00000000";
            return val.toFixed(8);
        },
        alterarPagina(pagina) {
            this.formSearch.pagina = pagina;
        },
        abriModalAprovarPagamento(idPedido) {
            this.idPedido = idPedido;
            this.modalConfirm = true;
        },
        async confirmAprovacao() {
            this.$loading(true);
            await api.get(`/Admin/approveOrder/${this.idPedido}`).then(
                success => {
                    this.$snotify.success("Pagamento aprovado com sucesso");
                    this.modalConfirm = false;
                    this.getPedidos();
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");
                }
            );
            this.$loading(false);
        },
        async confirmReprovacao() {
            this.$loading(true);
            await api.delete(`/Admin/disapproveOrder/${this.idPedido}`).then(
                success => {
                    this.$snotify.success("Pagamento reprovado com sucesso");
                    this.modalConfirmDelete = false;
                    this.getPedidos();
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");
                }
            );
            this.$loading(false);
        },
        abrirComprovante(url) {
            this.arquivoVisualizar = url;
            this.$bvModal.show('modal-comprovante');
        },
        fecharComprovante() {
            this.$bvModal.hide('modal-comprovante');
        }
    }
}