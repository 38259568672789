import http from '@/_helper/api-services'
import './ArvoreBinaria.scss'
import SideBar from "@/components/SideBar/SideBar.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import BoxArvoreBinaria from '../../components/BoxArvoreBinaria/BoxArvoreBinaria.vue'
import CardPontos from '@/components/CardPontos/CardPontos.vue';
import BarraToken from "@/components/BarraToken/BarraToken.vue";

export default {
    name: 'ArvoreBinaria',
    data() {
        return {
            login : '',
            dadosNivel_1: [],
            dadosNivel_2: [],
            dadosNivel_3: [],
            dadosNivel_4: [],
            userEsquerda: {
                login: String(),
                dataVinculo: null,
                nomeProduto: String(),
                posicao: Number(),
                pontos: Number()
            },
            userDireita: {
                login: String(),
                dataVinculo: null,
                nomeProduto: String(),
                posicao: Number(),
                pontos: Number()
            },
            dadosUsuario: {},
            countFake: -10001,
            qualificadoBinario: false,
            dataQualificacaoBinario: null,
            dadosUser: this.$store.getters['autenticacao/getLogin'].user 
        }
    },
    components: {
        SideBar,
        PremiosDashboard,
        BoxArvoreBinaria,
        CardPontos,
        BarraToken
    },
    beforeMount() {
        
    },
    mounted() {
        // this.obterDadosUsuario();
        this.$loading(true);
        this.verificarQualificacao();
        var dadosUsuario = this.$store.getters['autenticacao/getLogin'].user;        
        this.buscarDados(dadosUsuario.id);
    },
    methods: {
        // obterDadosUsuario() {
        //     const loggedIn = localStorage.getItem("user");
        //     if (loggedIn) {
        //         this.$loading(true);
        //         http.get('/Dashboard/getBarStatus/').then((responde) => {
        //             this.dadosUsuario = responde.data;

        //             if (this.dadosUsuario.limiteAtingido == true) {
        //                 this.$bvModal.show('modal-limit');
        //             }
        //         },error => {
        //             if (error.response && error.response.data && error.response.data.errors)
        //                 error.response.data.errors.forEach(e => {
        //                     this.$snotify.error(e);
        //                 });
        //             // else
        //             //     this.$snotify.error("An unexpected error has occurred");
        //         });
        //     }

        //     const forceCountry = localStorage.getItem("force-country");
        //     if (forceCountry) {
        //         this.$bvModal.show('modal-pais');
        //     }
        // },
        formatDate(data) {
            let dateTime = new Date(data);
            return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: false });
        },
        buscarDados(id){ 
            if (id == "")
                return false;

            this.$loading(true);           
            http.get('/network/getBinaryTree/' + id).then((responde) => {
                this.dadosNivel_1 = responde.data[0];
                this.dadosNivel_2 = this.verificarDados(responde.data.filter(x => x.nivel == 1), 2);
                this.dadosNivel_3 = this.verificarDados(responde.data.filter(x => x.nivel == 2), 3, this.dadosNivel_2.filter(x => x.nivel == 1));
                this.dadosNivel_4 = this.verificarDados(responde.data.filter(x => x.nivel == 3), 4, this.dadosNivel_3.filter(x => x.nivel == 2));
                this.$loading(false);
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });;
        },
        buscarLogin() {
            if (this.login == '') {
                this.$snotify.error(this.$i18n.t("erros.obrigatorio"));
                return;
            }

            this.$loading(true);           
            http.get('/network/getTreeBinarySearch/' + this.login).then((responde) => {
                this.dadosNivel_1 = responde.data[0];
                this.dadosNivel_2 = this.verificarDados(responde.data.filter(x => x.nivel == 1), 2);
                this.dadosNivel_3 = this.verificarDados(responde.data.filter(x => x.nivel == 2), 3, this.dadosNivel_2.filter(x => x.nivel == 1));
                this.dadosNivel_4 = this.verificarDados(responde.data.filter(x => x.nivel == 3), 4, this.dadosNivel_3.filter(x => x.nivel == 2));
                this.$loading(false);
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        verificarQualificacao(){ 
            this.$loading(true); 
            http.get('/network/verifyQualification').then((response) => {

                this.qualificadoBinario = response.data.qualificado;
                this.dataQualificacaoBinario = response.data.dataQualificacao;

                if (this.qualificadoBinario) {
                    this.userDireita.login = response.data.usuarioDireita.login;
                    this.userDireita.dataVinculo = response.data.usuarioDireita.dataVinculo;
                    this.userDireita.dataQualificacao = response.data.usuarioDireita.dataQualificacao;
                    this.userDireita.nomeProduto = response.data.usuarioDireita.nomeProduto;
                    this.userDireita.posicao = response.data.usuarioDireita.posicao;
                    this.userDireita.pontos = response.data.usuarioDireita.pontos;

                    this.userEsquerda.login = response.data.usuarioEsquerda.login;
                    this.userEsquerda.dataVinculo = response.data.usuarioEsquerda.dataVinculo;
                    this.userEsquerda.dataQualificacao = response.data.usuarioEsquerda.dataQualificacao;
                    this.userEsquerda.nomeProduto = response.data.usuarioEsquerda.nomeProduto;
                    this.userEsquerda.posicao = response.data.usuarioEsquerda.posicao;
                    this.userEsquerda.pontos = response.data.usuarioEsquerda.pontos;
                }
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        obterQualificadores(){ 
           
        },
        //Função de loop para gerar os dados por nivel
        verificarDados(dados, nivel, dadosPai) {
            var dadosFinal = [];
            var _nivel = (nivel == 4) ? nivel : (nivel - 1);
            for (var i = 0; i < _nivel; i++) {
                if (nivel == 2) {
                    dadosFinal.push(this.ifDadosReais(dados, nivel, 0)[0]);
                    dadosFinal.push(this.ifDadosReais(dados, nivel, 1)[0]);
                } else {
                    var pai = (dadosPai[i] != null) ? dadosPai[i].idMatriz : 0;
                    dadosFinal.push(this.ifDadosReais(dados, nivel, 0, pai)[0]);
                    dadosFinal.push(this.ifDadosReais(dados, nivel, 1, pai)[0]);
                }
            }
            return dadosFinal;
        },
        //Função para Pegar os dados do array original e atribuir ao novo array, ou colocar um registro FAKE.
        ifDadosReais(dados, nivel, posicao, pai) {
            var dadosFinal = [];
            this.countFake = this.countFake + 1;
            if (nivel == 2) {
                if (dados.filter(x => x.posicao == posicao).length == 0) {
                    dadosFinal.push(this.addDadosFake(nivel, posicao, this.countFake)[0]);
                } else {
                    dadosFinal.push(dados.filter(x => x.posicao == posicao)[0])
                }
            } else {
                if (dados.filter(x => x.posicao == posicao && x.idMatrizPai == pai).length == 0) {
                    dadosFinal.push(this.addDadosFake(nivel, posicao, this.countFake)[0]);
                } else {
                    dadosFinal.push(dados.filter(x => x.posicao == posicao && x.idMatrizPai == pai)[0])
                }
            }
            return dadosFinal;
        },
        //Array dados FAKE
        addDadosFake(nivel, posicao, matriz) {
            return [{
                dataCadastro: "1901-01-01T00:00:00.000",
                email: "",
                graduacao: "",
                idGraduacao: 0,
                idMatriz: matriz,
                idMatrizPai: 0,
                idUsuario: "",
                idUsuarioPai: "",
                login: "",
                nivel: (nivel-1),
                nome: "",
                posicao: posicao,
                valorProduto: 0,
                urlImagem: "https://blobinsight.blob.core.windows.net/dynamix/images/icon-logo.png"
            }];
        }
    }
}