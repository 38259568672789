import espanhol from './spanish.json'
import ingles from './english.json'
import chines from './chinese.json'
import filipino from './filipino.json'
import frances from './french.json'
import coreano from './korean.json'
import portugues from './portuguese.json'
import russo from './russian.json'
import tailandes from './thailandese.json'
import vietnamita from './vietnamese.json'
import alemao from './german.json'

export const defaultLocale = 'ingles'

export const languages = {
  espanhol: espanhol,
  ingles: ingles,
  chines: chines,
  filipino: filipino,
  frances: frances,
  coreano: coreano,
  portugues: portugues,
  russo: russo,
  tailandes: tailandes,
  vietnamita: vietnamita,
  alemao: alemao
}