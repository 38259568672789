import "./Gerais.scss";
import http from "@/_helper/api-services";
import ListaIdioma from '@/components/ListaIdioma/ListaIdioma.vue';
import DatePicker from "vue2-datepicker";
// import AppFooter from '@/components/AppFooter/appFooter.vue';

export default{
    name: "Gerais",
    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const minDate = new Date(today);

        minDate.setMonth(minDate.getMonth() - 1);
        return {
            dataInicialFiltro: minDate,
            dataFinalFiltro: today,
            fields: [
                { key: "text", label: this.$t('financeiro.tipo') },
                { key: "value", label: this.$t('financeiro.valor') }
            ],
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            dados: [],
            statusLogin: false,
            searchMenu: Boolean()
        }
    },
    components: {
        DatePicker,
        ListaIdioma,
        // AppFooter
    },
    beforeMount(){
        this.verifyLogged();
        window.addEventListener('resize', this.readjustPosition);
        this.$emit("hideHeaderFooter");
        this.obterRelatorio();
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.readjustPosition);
    },
    mounted(){
    },
    methods: {
        obterRelatorio(){
            this.$loading(true);

            let data = {
                DataInicial: this.dataInicialFiltro,
                DataFinal: this.dataFinalFiltro,
            };
            
            http.post("/generalReport/searchReport", data).then(
                success => {
                    this.dados = [];
                    for (var key in success.data) {
                        if (key != 'saldoAtualUSDT')
                            this.dados.push({ text: key, value: success.data[key] });
                    }
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                            error.response.data.errors.forEach(e => { this.$snotify.error(e); });
                }
            )
            this.$loading(false);
        },
        formatDecimal(value, decimalPlaces) {
            if (value) {
                return (value / 1).toFixed(decimalPlaces);
            }

            return '0.00'
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        adjustFooterPadding(heightFooter) {
            this.paddingBottom = `${heightFooter + 40}px`;
        },
        readjustPosition() {
            setTimeout(() => { this.switchPanel(this.action) }, 450);
        },
        verifyLogged(){
            const isLogged = localStorage.getItem("user");

            if(isLogged){
                this.statusLogin = true;
            }
        }
    }
}