import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home/Home.vue";
import Login from "./views/Login/Login.vue";
import Cadastro from "./views/Cadastro/Cadastro.vue";
import RecuperarSenha from "./views/RecuperarSenha/RecuperarSenha.vue";
import NotFound from "./views/PageNotFound/PageNotFound.vue";
import ResetPassword from "./views/ResetPassword/resetPassword.vue";
import ArvoreBinaria from "./views/ArvoreBinaria/ArvoreBinaria.vue";
import ParceiroDireto from "./views/ParceiroDireto/ParceiroDireto.vue";
import ExtratoBancario from "./views/ExtratoBancario/ExtratoBancario.vue";
// import SureBet from "./views/SureBet/SureBet.vue";
import GestaoAnuncio from "./views/GestaoAnuncio/GestaoAnuncio.vue";
import PlanoCarreira from "./views/PlanoCarreira/PlanoCarreira.vue";
import Arbitragem from "./views/Arbitragem/Arbitragem.vue";
import Store from "./views/Store/Store.vue";
import EAcademy from "./views/EAcademy/EAcademy.vue";
import Logout from "./views/Logout/logout.vue";
import CompletarDados from "./views/CompletarDados/CompletarDados.vue";
import ConfirmEmail from "./views/ConfirmEmail/confirmEmail.vue";
import ConfirmSaque from "./views/ConfirmSaque/confirmSaque.vue";
import Saque from "./views/Saque/saque.vue";
import Produtos from "./views/Produtos/produtos.vue";
import Ingressos from "./views/Ingressos/ingressos.vue";
import MeusDados from "./views/MeusDados/MeusDados.vue";
import MinhasCompras from "./views/MinhasCompras/MinhasCompras.vue";
import Index from "./views/Index/index.vue";
import AutoBot from "./views/AutoBot/index.vue";
import Usuarios from "./views/Admin/Usuarios/usuarios.vue";
import AtivarContas from "./views/AtivarContas/AtivarContas.vue";
import SemPermissao from "./views/SemPermissao/semPermissao.vue";
import AlterarDadosUsuario from "./views/Admin/AlterarDadosUsuario/alterarDadosUsuario.vue";
import MeusCursos from "./views/MeusCursos/meusCursos.vue";
import ResumoUsuario from "./views/Admin/ResumoUsuario/ResumoUsuario.vue";
import CadastroComunicado from "./views/Admin/CadastroComunicado/cadastroComunicado.vue";
import Comunicados from "./views/Admin/Comunicados/comunicados.vue";
import Pagamentos from "./views/Admin/Pagamentos/pagamentos.vue";
import GerenciarJogos from "./views/Admin/GerenciarJogos/gerenciarJogos.vue";
import GerenciarCampeonatos from "./views/Admin/GerenciarCampeonatos/GerenciarCampeonatos.vue";
import Arquivos from "./views/Suporte/Arquivos/Arquivos.vue";
import Contato from "./views/Suporte/Contato/Contato.vue";
import FAQ from "./views/Suporte/FAQ/faq.vue";
import MinhaRede from "./views/MinhaRede/MinhaRede.vue";
import AprovarCarteira from "./views/AprovarCarteira/AprovarCarteira.vue";
import Transferencia from "./views/Transferencia/Transferencia.vue";
import FaqAdmin from "./views/Admin/GerenciarFAQ/gerenciarfaq.vue";
import RelUsuario from "./views/Relatorios/Usuarios/Usuarios.vue";
import RelSaques from "./views/Relatorios/Saques/Saques.vue";
import LancamentoManual from "./views/Admin/LancamentoManual/LancamentoManual.vue";
import Dashboard from "./views/Admin/Dashboard/Dashboard.vue";
import Categorias from "./views/Admin/Categorias/categorias.vue";
import Configuracoes from "./views/Admin/Configuracoes/configuracoes.vue";
import GerenciarGrupos from "./views/Admin/GerenciarGrupos/GerenciarGrupos.vue";
import UsuariosAdministrativos from "./views/Admin/UsuariosAdministrativos/usuariosAdministrativos.vue";
import GerenciarGanhadores from "./views/Admin/GerenciarGanhadores/GerenciarGanhadores.vue";
import GerenciarArquivos from "./views/Admin/GerenciarArquivos/arquivos.vue";
import Cursos from "./views/Cursos/Cursos.vue";
import GerenciarCursos from "./views/Admin/GerenciarCursos/GerenciarCursos.vue";
import EditarCurso from "./views/Admin/GerenciarCursos/EditarCurso/EditarCurso.vue";
import AdicionarCurso from "./views/Admin/GerenciarCursos/AdicionarCurso/AdicionarCurso.vue";
import GerenciarPedidos from "./views/Admin/GerenciarPedidos/gerenciarPedidos.vue";
import GerenciarIngressos from "./views/Admin/GerenciarIngressos/gerenciarIngressos.vue";
import RendimentoDiario from "./views/Admin/RendimentoDiario/RendimentoDiario.vue";
import Tickets from "./views/Suporte/Tickets/Tickets.vue";
import TicketsAdmin from "./views/Admin/Suporte/TicketsAdmin.vue";
import ContatoAdmin from "./views/Admin/Suporte/ContatoAdmin/ContatoAdmin.vue";
import LancamentoPontos from "./views/Admin/LancamentoPontos/LancamentoPontos.vue"
import RelatorioGeral from "./views/Relatorios/Gerais/Gerais.vue"
import EmBreve from "./views/EmBreve/EmBreve.vue"
import DadosCompra from "./views/Store/DadosCompra/DadosCompra.vue"
import GerenciarWallets from "./views/Admin/GerenciarWallets/GerenciarWallets.vue"
import AtivarPacote from "./views/AtivarPacote/AtivarPacote.vue"
import ExtratoSolarcoin from "./views/ExtratoSolarcoin/ExtratoSolarcoin.vue"
import TransferSaldo from "./views/TransferSaldo/TransferSaldo.vue"
import BotDinamico from "./views/BotDinamico/BotDinamico.vue";
import GerenciarBots from "./views/Admin/GerenciarBots/GerenciarBots.vue"
import GerenciarGraduacao from "./views/Admin/GerenciarGraduacao/GerenciarGraduacao.vue"
import Auditoria from "./views/Admin/Auditoria/Auditoria.vue"
import MinhasOperacoes from "./views/MinhasOperacoes/MinhasOperacoes.vue"
import PagarPedido from "./views/PagarPedido/PagarPedido.vue"
import RelatorioUsuarioPedidos from "./views/Admin/RelatorioUsuarioPedidos/RelatorioUsuarioPedidos.vue";
// import Voucher from "./views/Voucher/Voucher.vue"


Vue.use(Router);

const router = new Router({
    // mode: "history",
    base: process.env.BASE_URL,
    linkExactActiveClass: "is-active",
    routes: [
        // {
        //     path: "/index",
        //     name: "",
        //     component: Login,
        // },
        {
            path: "/index",
            name: "Index",
            component: Index,
        },
        {
            path: "/autobot",
            name: "AutoBot",
            component: AutoBot,
        },
        {
            path: "/",
            name: "Home",
            component: Home,
        },
        {
            path: "/login",
            name: "Login",
            component: Login,
        },
        {
            path: "/login/:sponsor",
            name: "Login",
            component: Cadastro,
        },
        {
            path: "/register/:sponsor?",
            name: "Cadastro",
            component: Cadastro,
        },
        {
            path: "/forgot-password",
            name: "RecuperarSenha",
            component: RecuperarSenha,
        },
        {
            path: "/confirm-email/:token",
            name: "Confirm Email",
            component: ConfirmEmail,
        },
        {
            path: "/confirm-saque/:token",
            name: "Confirm Withdrawal",
            component: ConfirmSaque,
        },
        {
            path: "/reset-password/:token",
            name: "Reset password",
            component: ResetPassword,
        },
        {
            path: "/binary-tree",
            name: "Árvore Binária",
            component: ArvoreBinaria,
        },
        {
            path: "/my-directs",
            name: "Parceiros Direto",
            component: ParceiroDireto,
        },
        {
            path: "/bank-statement",
            name: "Extrato Bancário",
            component: ExtratoBancario,
        },
        // {
        //     path: "/surebet",
        //     name: "SureBet",
        //     component: SureBet,
        // },
        {
            path: "/campanhas-trafego",
            name: "GestaoAnuncio",
            component: GestaoAnuncio,
        },
        {
            path: "/career-plan",
            name: "PlanoCarreira",
            component: PlanoCarreira,
        },
        {
            path: "/operations",
            name: "Arbitragem",
            component: Arbitragem,
        },
        {
            path: "/solarcoin-extract",
            name: "Extrato Solarcoin",
            component: ExtratoSolarcoin,
        },
        {
            path: "/transfer-balance",
            name: "Transfer Saldo",
            component: TransferSaldo,
        },
        {
            path: "/store",
            name: "Store",
            component: Store,
        },
        {
            path: "/store/purchasing-data/:codigoPedido?",
            name: "Dados compra",
            component: DadosCompra,
        },
        {
            path: "/e-academy",
            name: "EAcademy",
            component: EAcademy,
        },
        {
            path: "/activate-package",
            name: "Ativar Pacote",
            component: AtivarPacote,
        },
        {
            path: "/logout",
            name: "Logout",
            component: Logout,
        },
        {
            path: "/complete-data",
            name: "Completar Dados",
            component: CompletarDados,
        },
        {
            path: "/event-tickets",
            name: "Event tickets",
            component: Ingressos,
        },
        {
            path: "/withdrawal",
            name: "Saque",
            component: Saque,
        },
        {
            path: "/investiment",
            name: "Products",
            component: Produtos,
        },
        {
            path: "/my-data",
            name: "MeusDados",
            component: MeusDados,
        },
        {
            path: "/my-purchases/:codigoPedido?",
            name: "MinhasCompras",
            component: MinhasCompras,
        },
        {
            path: "/admin/users",
            name: "Usuarios",
            component: Usuarios,
        },
        {
            path: "/admin/activate-accounts",
            name: "Ativar contas",
            component: AtivarContas,
        },
        {
            path: "/no-permission",
            name: "Sem permissão",
            component: SemPermissao,
        },
        {
            path: "/support/tickets",
            name: "Tickets",
            component: Tickets,
        },
        {
            path: "/admin/change-user-data/:idUsuario",
            name: "Alterar dados usuário",
            component: AlterarDadosUsuario,
        },
        {
            path: "/my-courses",
            name: "Meus cursos",
            component: MeusCursos,
        },
        {
            path: "/courses/:idCurso",
            name: "Cursos",
            component: Cursos,
        },
        {
            path: "/admin/manage-courses/",
            name: "Gerenciar Cursos",
            component: GerenciarCursos,
        },
        {
            path: "/admin/manage-courses/editar/:idCurso",
            name: "Editar Curso",
            component: EditarCurso,
        },
        {
            path: "/admin/manage-courses/adicionar",
            name: "Adicionar Curso",
            component: AdicionarCurso,
        },
        {
            path: "/admin/user-summary/:idUsuario",
            name: "Resumo usuário",
            component: ResumoUsuario,
        },
        {
            path: "/admin/communicated",
            name: "Comunicados",
            component: Comunicados,
        },
        {
            path: "/admin/communicated/cadastrar/:idMensagem?",
            name: "Cadastro de comunicados",
            component: CadastroComunicado,
        },
        {
            path: "/admin/payments",
            name: "Pagamentos",
            component: Pagamentos,
        },
        {
            path: "/admin/manage-games",
            name: "gerenciarJogos",
            component: GerenciarJogos,
        },
        {
            path: "/admin/manage-competitions",
            name: "gerenciarCampeonatos",
            component: GerenciarCampeonatos,
        },
        {
            path: "/support/files",
            name: "Arquivos",
            component: Arquivos,
        },
        {
            path: "/support/contact:code?",
            name: "Contato",
            component: Contato,
        },
        {
            path: "/admin/support/contact:code?",
            name: "ContatoAdmin",
            component: ContatoAdmin,
        },
        {
            path: "/faq",
            name: "FAQ",
            component: FAQ,
        },
        {
            path: "/approve-wallet/:idCarteira",
            name: "Aprovar Carteira",
            component: AprovarCarteira,
        },
        {
            path: "/my-network",
            name: "Minha Rede",
            component: MinhaRede,
        },
        {
            path: "/transfer",
            name: "Transferencia",
            component: Transferencia,
        },
        {
            path: "/admin/manage-faq",
            name: "Admin Faq",
            component: FaqAdmin,
        },
        {
            path: "/admin/manual-launch",
            name: "Lançamento manual",
            component: LancamentoManual,
        },
        {
            path: "/admin/manual-points",
            name: "Lançamento Pontos",
            component: LancamentoPontos,
        },
        {
            path: "/admin/dashboard",
            name: "Dashboard",
            component: Dashboard,
        },
        {
            path: "/admin/admin-users",
            name: "Usuarios Administrativos",
            component: UsuariosAdministrativos,
        },
        // {
        //     path: "/reports/users",
        //     name: "Relatório de Usuários",
        //     component: RelUsuario,
        // },
        {
            path: "/reports/withdraws",
            name: "Relatório de Saques",
            component: RelSaques,
        },
        {
            path: "/admin/categories",
            name: "Categorias",
            component: Categorias,
        },
        {
            path: "/reports/users",
            name: "Relatório de Usuario",
            component: RelatorioUsuarioPedidos,
        },
        {
            path: "/admin/report-user",
            name: "Relatório de Usuario",
            component: RelatorioUsuarioPedidos,
        },
        {
            path: "/admin/settings",
            name: "Configuracoes",
            component: Configuracoes,
        },
        {
            path: "/admin/manage-groups",
            name: "Gerenciar Grupos",
            component: GerenciarGrupos,
        },
        {
            path: "/admin/manage-winners",
            name: "Gerenciar Ganhadores",
            component: GerenciarGanhadores,
        },
        {
            path: "/admin/manage-files",
            name: "Gerenciar Arquivos",
            component: GerenciarArquivos,
        },
        {
            path: "/admin/report-orders",
            name: "Relatório de pedidos",
            component: () =>
                import("@/views/Admin/RelatorioPedidos/RelatorioPedidos.vue"),
        },
        {
            path: "/admin/report-emails",
            name: "Relatório de e-mails",
            component: () =>
                import("@/views/Admin/RelatorioEmails/RelatorioEmails.vue"),
        },
        {
            path: "/admin/manage-orders",
            name: "Gerenciar pedidos",
            component: GerenciarPedidos
        },
        {
            path: "/admin/event-tickets",
            name: "Gerenciar Ingressos",
            component: GerenciarIngressos
        },
        {
            path: "/admin/support/tickets",
            name: "TicketsAdmin",
            component: TicketsAdmin,
        },
        {
            path: "/ads",
            name: "Anuncios",
            component: () =>
                import("./views/Anuncios/Anuncios.vue"),
        },
        {
            path: "/show/:idAnuncio",
            name: "Assistir",
            component: () =>
                import("./views/Assistir/Assistir.vue"),
        },
        {
            path: "/admin/manage-ads",
            name: "Gerenciar Anuncios",
            component: () =>
                import("./views/Admin/GerenciarAnuncios/GerenciarAnuncios.vue"),
        },
        {
            path: "/admin/manage-gain",
            name: "Gerenciar Rendimento Diário",
            component: () =>
                import("./views/Admin/RendimentoDiario/RendimentoDiario.vue"),
        },
        {
            path: "/financial-report",
            name: "Relatório Geral",
            component: RelatorioGeral
        },
        {
            path: "/admin/manage-wallets",
            name: "Gerenciar Wallets",
            component: GerenciarWallets,
        },
        {
            path: "/dynamic-bot",
            name: "Bot dinamico",
            component: BotDinamico,
        },
        {
            path: "/admin/manage-bots",
            name: "Gerenciar Bots",
            component: GerenciarBots
        },
        {
            path: "/admin/manage-graduation",
            name: "Gerenciar Graduação",
            component: GerenciarGraduacao
        },
        {
            path: "/admin/audit",
            name: "Auditoria",
            component: Auditoria
        },
        {
            path: "/my-contracts",
            name: "Meus Contratos",
            component: MinhasOperacoes
        },
        {
            path: "/pay-order",
            name: "Pagar pedido",
            component: PagarPedido
        },
        // {
        //     path: "/versocode",
        //     name: "Voucher",
        //     component: Voucher
        // },

        { path: "*", component: NotFound },
    ],
});

router.beforeEach((to, from, next) => {
    const publicPages = [
        "index",
        "autobot",
        "kriptocoin",
        "login",
        "register",
        "forgot-password",
        "logout",
        "reset-password",
        "confirm-email",
        "confirm-saque",
        "approve-wallet",
        "support/contact",
        "admin/support/contact",
        "/financial-report"
    ];
    const authRequired = !(
        publicPages.filter((p) => to.path.indexOf(p) != -1).length > 0
    );
    const loggedIn = localStorage.getItem("user");

    if (authRequired && !loggedIn && to.path.indexOf("/register") == -1) {
        return next("/index");
    }

    if (loggedIn) {
        let json = JSON.parse(loggedIn);
        let urlsAvailable = JSON.parse(localStorage.getItem("menu"));
        if (to.path.indexOf("/logout") != 0 && to.path.indexOf("/login") != 0 && to.path.indexOf("/register") != 0 && to.path.indexOf("/forgot-password") != 0) {
            // if (to.path.indexOf("/completar-dados") == -1) {
            //   if (!json.dadosCompletos) return next("/completar-dados");
            // }
            if (to.path != "/" && to.path != "/no-permission" && urlsAvailable) {
                let canAccess = false;
                urlsAvailable
                    .filter((u) => u.url != "/")
                    .every((menu) => {
                        if (menu.url != "#") canAccess = to.path.indexOf(menu.url) == 0;
                        else {
                            menu.subMenus
                                .filter((u) => u.url != "/")
                                .every((submenu) => {
                                    canAccess = to.path.indexOf(submenu.url) == 0;
                                    return !canAccess;
                                });
                        }
                        return !canAccess;
                    });
                if (to.path.indexOf('/assistir') > -1) return next();
                if (to.path.indexOf('/surebet') > -1) return next();
                if (!canAccess) return next("/no-permission");
            }
        }
    }
    next();
});

export default router;